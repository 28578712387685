<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_le">
        <div class="footer_logo">
          <img
            src="../assets/logo.png"
            alt=""
          />海量精品素材，让创作灵感瞬间爆棚
        </div>
        <div class="footer_kf"><img @click="openKefu" src="../assets/kefu.png" alt="" /></div>
      </div>
      <div class="footer_top_ri">
        <div class="footer_title">政策说明</div>
        <div class="footer_xieyi">
          <div
            class="footer_xieyi_item"
            v-for="(item, index) in agreements"
            :key="index"
          >
            <span @click="navigateTo(item.link)">{{ item.text }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <p>为了防范电信网络诈骗，如网民接到962110电话，请立即接听</p>
      <p>
        {{ config.siteName }}隶属于{{
          config.company
        }}，是国内深受用户欢迎的设计素材网站，网站素材原创商用，快速下载，正版授权
      </p>
      <p>
        <a href="https://beian.miit.gov.cn/#/Integrated/index">{{
          config.beian
        }}</a>
      </p>
      <p>联系我们：{{ config.contact }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {},
  data() {
    return {
      agreements: [
        { text: "用户协议", link: "announce" },
        { text: "版权声明", link: "copyright" },
        { text: "售后服务", link: "customer" },
        { text: "隐私政策", link: "privacy" },
        { text: "会员协议", link: "vip" },
      ],
      config: "",
    };
  },
  mounted() {
    let that = this;
    axios
      .get("https://shop.renguokeji.com/app/v1/config")
      .then(function (response) {
        that.config = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    navigateTo(link) {
      if (this.$route.name !== link) {
        this.$router.push({name:link}).then(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    openKefu(){
			window.open('https://kf.renguokeji.com/kefu/?app=lishun&amp;extra=eyJpZCI6IjljMjA3Yjk1Y2FhOWUzZWE2NmVlYmIxZjNmNDk0ZjE3IiwidXNlcl9pZCI6IjljMjA3Yjk1Y2FhOWUzZWE2NmVlYmIxZjNmNDk0ZjE3IiwiZGV2aWNlX2lkIjoiOWMyMDdiOTVjYWE5ZTNlYTY2ZWViYjFmM2Y0OTRmMTciLCJwbGF0Zm9ybSI6ImFuZHJvaW');
		},
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  background: #141414;
  color: #d5d5d5;
  font-size: 14px;
  box-sizing: border-box;
  padding: 45px 6em 24px 6em;
  cursor: default;
}
.footer_top {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1.5px solid #202020;
  padding-bottom: 10px;
}
.footer_logo {
  display: flex;
  align-items: center;
}
.footer_logo img {
  width: 80px;
  margin-right: 20px;
}
.footer_kf img {
  width: 130px;
  margin-top: 20px;
  cursor: pointer;
}
.footer_top_ri {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #adadad;
}
.footer_title {
  margin-right: 40px;
  font-size: 16px;
}
.footer_xieyi {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}
.footer_xieyi_item {
  width: 100px;
  text-align: center;
  margin-bottom: 30px;
}
.footer_xieyi_item span {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.footer_xieyi_item span:hover {
  color: #ccc;
  font-weight: bold;
}
.footer_bottom {
  margin-top: 30px;
  text-align: center;
  color: #7a7c7e;
}
.footer_bottom p {
  margin-bottom: 6px;
}
.footer_bottom a {
  color: #7a7c7e;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
.footer_bottom a:hover,
.footer_bottom a:active {
  color: #ccc;
}
</style>
