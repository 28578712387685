<template>
  <div class="main">
    <Navbar></Navbar>
    <div class="content">
      <div class="main-right fr">
        <h2>VIP会员服务协议</h2>
        <p>更新时间：2023年 10 月 07 日<br />生效时间：2023年 10 月 07 日</p>
        <h3>VIP会员服务协议</h3>
        <p>
          欢迎使用利顺经营者（西华县利顺信息技术有限公司）以下简称“利顺”的VIP会员服务，为了保障您的权益，请在进行下一步操作前，
          详细阅读本协议的所有内容，特别是免除或限制责任的相应条款。当您勾选《VIP会员服务协议》行为或其他类似含义的行为时，
          则表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与利顺达成的协议，具有法律效力。
        </p>
        <p>
          如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
        </p>
        <h3>一、【协议的范围】</h3>
        <h4>1.1【协议适用主体范围】</h4>
        <p>
          本协议是您与利顺经营者（西华县利顺信息技术有限公司）之间关于您使用本服务所订立的协议。
        </p>
        <h4>1.2【协议的内容】</h4>
        <p>
          本协议视为《注册声明》、《版权声明》、《隐私政策》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。
          本协议内容包括本协议正文、利顺已经发布和/或将来可能发布的与利顺VIP会员服务相关的各类规则。所有规则为本协议不可分割的组成部分，
          与本协议具有同等法律效力。一旦相关内容发生变动，利顺将在相关页面提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；
          如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。
        </p>
        <h3>二、【关于本服务】</h3>
        <p>
          2.1
          VIP会员增值服务，指按照利顺的指定方式支付一定服务费用之后，用户可以享有由利顺提供的利顺产品、下载权限等方面特权的服务，简称为：本服务。
        </p>
        <p>
          2.2
          服务项目，指利顺可能会根据不同的产品及服务类型，推出不同种类的VIP会员增值服务，目前，利顺提供的服务项目有广告设计VIP、元素背景VIP、电商设计VIP、装饰装修VIP、摄影图VIP、插画VIP、UI新媒体VIP、视频音频VIP、办公文档VIP、字体VIP等
          不同种类的VIP会员增值服务，同时，利顺也可能会根据用户的需求、产品及服务类型的变化等，对现有VIP会员增值服务种类进行调整以及不断推出新的VIP会员增值服务种类。您认可并同意，您所享有的用户权益取决于您购买时充值的服务类型所涵盖的服务内容。您所享有的用户权益不会因为利顺服务项目的调整而增加，例如：若您充值下载SVIP会员时，下载SVIP会员仅包含广告设计、元素背景、电商设计、装饰装修、摄影图、插画、UI新媒体、视频音频、办公文档、字体十项VIP会员权限，之后利顺推出了新的服务项目云设计会员、抠图等工具会员，利顺有权拒绝为您开通云设计、抠图等VIP会员。若您充值下载个人VIP，会员仅包含个人下载VIP或个人商用SVIP，不含企业专属及其他需另行付款增值服务。
        </p>
        <p>
          2.3
          利顺也可能会在不同时间推出具体不同的服务内容，以不断完善、优化本服务。具体VIP会员增值服务种类及服务内容以相关服务页面公布、实际提供的内容为准。您可以自行根据需要选择相应服务。
        </p>
        <p>
          2.4
          您所享有的本服务的具体内容可能会因为级别、是否年费、开通期限、您选择的具体服务类别等因素而有不同，购买多个使用范围相同的服务的，高等级套餐覆盖低等级套餐，低等级套餐一概不予退款。具体以相关服务页面公布、实际提供的内容为准。
        </p>
        <p>
          2.5
          成为VIP会员后，VIP会员有权随时停止接受利顺的服务，但不得主张VIP会员服务费的退还。
        </p>
        <p>
          2.6
          您理解并同意利顺有权根据实际业务需要不时地调整其网站内容而无需另行通知您，包括但不限于内容增减、授权期限、收费模式等等。
        </p>
        <h3>三、权利义务</h3>
        <h4>3.1【关于收费】</h4>
        <p>
          本服务是利顺提供的收费服务，您须在按照本服务的收费标准支付相应费用后，方可使用本服务。此外，您也可以通过利顺认可的其他方式享有、使用本服务。
        </p>
        <p>
          利顺可能会根据本服务的整体规划，对本服务的收费标准、方式等进行修改和变更，前述修改、变更，利顺将在相应服务页面进行展示。您若需要获取、使用本服务，请先提前明悉当时关于本服务的收费标准、方式等信息。
        </p>
        <p>
          利顺有权单方根据本服务的整体规划对其提供的虚拟产品(包括但不限于VIP会员)的收费标准、方式等进行修改和变更，且利顺在此获得您的授权可以根据平台统一调整比例调整您已经获取的虚拟产品;
          基于虚拟产品的性质和特征，您所获取的虚拟产品不能够进行退货、换货、兑换现金，亦不得用于商业领域如买卖、置换、抵押等。一切通过非官方公布渠道取得的虚拟产品及其衍生服务均不获得保护，利顺有权中止或终止对您的服务。
        </p>
        <p>
          您充分了解并同意，利顺采用自动续订或自动续费的方式向您提供服务。利顺在此获得您的授权后可以根据首个服务推荐的规则向用户提供自动续订或自动续费服务，并在自动续订或自动续费前以短信、消息推送等显著方式提醒您。
        </p>
        <h4>3.2【服务开通】</h4>
        <p>
          3.2.1您应该通过利顺指定的包括但不限于支付宝、微信支付等现有或未来新增的方式依约支付一定费用后开通本服务。本服务开通之后，适用“一人一账号”原则，各个账号之间的使用权限不得更改或转移、互赠、售卖、租借、分享给任何第三人，各账号使用权限有且仅有开通该账号的用户享有，否则，利顺有权对该账号进行封号、中止或终止对您的服务。请您在登录/充值/参加活动时注意区分，避免造成损失。若造成损失的，由您自行承担。
        </p>
        <p>
          3.2.2利顺仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由VIP会员自行负担。
        </p>
        <p>
          3.2.3您在使用本服务时不得利用本服务从事以下行为，否则利顺有权取消VIP会员的服务资格，有此引发的问题由VIP会员自行承担，利顺不负任何责任。禁止行为包括但不限于：
        </p>
        <p>
          （1）发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽、暴力的内容；
        </p>
        <p>
          （2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
        </p>
        <p>（3）虚构事实、隐瞒真相以误导、欺骗他人；</p>
        <p>（4）发表、传送、传播广告信息及垃圾信息；</p>
        <p>（5）从事其他违反法律法规、政策及公序良俗、社会公德等的行为。</p>
        <p>
          （6）通过任何方式搜集本服务中其他用户的用户名、电子邮件等相关信息，并以发送垃圾邮件、连锁邮件、垃圾短信、即时消息等方式干扰、骚扰其他用户；
        </p>
        <p>（7）通过本服务发布包含广告、宣传、促销等内容的信息；</p>
        <p>（8）将本服务提供的内容再次许可他人使用；</p>
        <p>（9）以营利、经营等非个人使用的目的为自己或他人开通本服务；</p>
        <p>
          （10）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何作弊程序、软件方式为自己或他人开通或使用本服务；
        </p>
        <p>
          （11）通过任何不正当手段或以违反诚实信用原则的方式为自己或他人开通或使用本服务；
        </p>
        <p>（12）通过非利顺指定的方式为自己或他人开通本服务；</p>
        <p>（13）通过侵犯利顺或他人合法权益的方式为自己或他人开通本服务；</p>
        <p>
          （14）通过其他违反相关法律、行政法规、国家政策等的方式为自己或他人开通本服务。
        </p>
        <p>
          （15）转让、转售、授予分许可、出租、捐赠或以其他方式转让开通服务获得的内容给第三方。
        </p>
        <p>（16）其他未经利顺许可的行为。</p>
        <h4>3.3【服务期限】</h4>
        <p>
          3.3.1本服务的服务期限以您自行选择并支付相应服务费用的期限为准，您也可以登录利顺充值中心或者本服务的相应页面查询。若您希望在有效期届满后继续享受会员服务，则需要重新购买。
        </p>
        <p>3.4【对自己行为负责】</p>
        <p>
          3.4.1您充分了解并同意，您必须为自己注册账号下的一切行为负责，包括但不限于您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。
        </p>
        <p>
          3.4.2您充分了解并同意，会员服务仅限您本人使用，同时，您保证您将合理使用会员服务，不利用会员服务非法获利，不以任何形式将您所享有的会员服务内容提供给他人使用或为他人提供免费/有偿的代下载，否则利顺有权取消您的会员资格且您已支付的会员服务费用不予退还，因此产生的相关责任及损失均由您自行承担，如您的上述行为给利顺造成损失的，利顺保留向您追偿的权利。
        </p>
        <p>3.5【服务的变更、中止或终止】</p>
        <p>
          您充分了解并同意，由于互联网服务的特殊性，利顺可能会按照相关法规、双方约定或在其他必要时，中止或终止向您提供本服务，届时，利顺会依法保护您的合法权益。
        </p>
        <h3>四、【违约责任】</h3>
        <p>
          4.1如果利顺发现或收到他人举报您有违反本协议任何行为的，利顺有权依法进行独立判断并采取技术手段予以删除、屏蔽或断开相关的信息。同时，利顺有权视您的行为性质，对您采取包括但不限于暂停或终止部分或全部本服务、中止或终止您对利顺账号使用、追究您法律责任等措施，利顺也无需向您退还任何费用，而由此给您带来的损失（包括但不限于通信中断、相关数据清空、未使用的服务费用作为违约金而归利顺所有等），由您自行承担，造成利顺损失的，您也应予以赔偿。
        </p>
        <h4>4.2【对第三方损害的处理】</h4>
        <p>
          您有违反本协议任何行为，导致任何第三方损害的，您应当独立承担责任；利顺因此遭受损失的，您也应当一并赔偿。
        </p>
        <h3>五、会员售后服务</h3>
        <p>
          5.1
          您知悉并确认，开通会员服务后，作品一经下载消耗即获得授权，若您中途主动取消服务或终止资格或被利顺根据《利顺络服务使用协议》、本协议及相关规则注销账号、终止会员资格的，您已支付的会员服务费用将不予退还。
        </p>
        <p>
          5.2
          如您有其他与会员服务售后相关的问题，可以通过利顺公布的联系方式联系客服进行反馈。
        </p>
        <p>
          5.3
          若您已使用下载服务，因提供下载服务的特殊性，一经下载文件将会存储于您的电脑，代表您已获得授权，不支持退款。若逾期未开通账户或开通账号后无法下载的您可联系利顺售后解决相关问题，除您购买的会员因利顺站故障不能使用外，一律不接受退款申请。
        </p>
        <p>5.4 关于保价</p>
        <p>
          5.4.1
          利顺用户可享受保价承诺，利顺承诺：自通过利顺官方充值渠道购买会员之日起7天内，如遇利顺官方充值渠道中同会员销售价格下调，利顺将退还差价。
        </p>
        <p>
          5.4.2“同会员”指：会员有效期相同、会员类目相同、会员所提供服务相同、会员所购买渠道相同。
        </p>
        <p>
          5.4.3“利顺官方充值渠道”指：利顺非活动页面充值开通利顺会员的渠道。
          示例链接： https://www.58pic.com/index.php?m=sponsor&amp;a
        </p>
        <p>5.4.4“销售价格”指：利顺VIP官方充值渠道中展示的会员实时售价。</p>
        <p>
          5.4.5
          若会员用户自会员购买之日起7天内，发现同会员销售价格下调，可立即联系利顺客服，并提供相关会员链接信息或截图。
        </p>
        <p>
          5.4.6 若经核实通过、利顺会将差价在7个工作日内通过支付渠道原路退还。
        </p>
        <p>
          5.4.7 除官方充值渠道外的特殊活动会员（包括但不限于利顺“拼团““秒杀”
          “砍价”活动、使用优惠券充值或第三方充值渠道）不参与本保价政策，亦不可作为差价退还的参照标准。
        </p>
        <h3>六、【其他】</h3>
        <h4>6.1【协议的生效】</h4>
        <p>您使用本服务即视为您已阅读并同意受本协议的约束。</p>
        <h4>6.2【适用法律】</h4>
        <p>
          本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
        </p>
        <h4>6.3【争议解决】</h4>
        <p>
          若您和利顺之间发生任何纠纷或争议，首先应友好协商解决；
        </p>
        <h4>6.4【条款标题】</h4>
        <p>
          本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        </p>
        <h4>6.5【条款效力】</h4>
        <p>
          本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </p>
      </div>
    </div>
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.main {
  background-color: #f6f6f6;
}
.content {
  width: 90%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 6px;
  margin: 30px auto;
}
.content h2 {
  font-size: 20px;
  color: #333;
  text-align: center;
}
.content h3 {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content h4 {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content .text-b {
  font-weight: 700;
}

.content p {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-bottom: 20px;
}

.content p i {
  font-weight: 400;
  text-align: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #9a9a9a;
  display: inline-block;
  line-height: 16px;
  border-radius: 50%;
  border: 1px solid #9a9a9a;
  margin-right: 8px;
  vertical-align: 1px;
}
</style>