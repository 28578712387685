<template>
  <div class="main">
    <Navbar></Navbar>
    <div class="content">
      <div class="main-right fr">
        <h2>隐私政策</h2>
        <p>更新时间：2024年 04 月 24 日<br />生效时间：2024年 04 月 24 日</p>
        <p>
          西华县利顺信息技术有限公司-利顺（以下简称“我们”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。因此我们希望您可以<strong
            >仔细阅读、基于您的充分理解，以明确的方式，做出您认可的真实意思表示。</strong
          >
        </p>
        <p>
          请在使用我们的产品（或服务）前，<strong
            >仔细阅读和完全理解本政策后选择是否同意本政策的内容</strong
          ><strong>。</strong><strong>如您已明确表示不同意本</strong
          ><strong>政策</strong
          ><strong
            >，我们将不会按照本政策的规定对您的个人信息以收集、保存等形式处理，但请您知悉，这将影响您正常使用我们的产品和/或服务；如您使用或继续使用我们的产品和/或服务，或在我们更新本隐私政策后（我们会在每次更新时</strong
          ><strong>作出</strong><strong>提示）</strong><strong>选</strong
          ><strong>择</strong><strong>“我已阅读并同意”</strong
          ><strong
            >或继续使用我们的产品/服务，即意味着您同意本隐私政策及其更新内容，并且同意授权我们按照本隐私政策收集</strong
          ><strong>、</strong
          ><strong
            >处理和保护您的信息。其中，与您的权益可能存在重大关系的条款我们已用加粗等形式予以提示，请特别关注。</strong
          >
        </p>
        <p><strong># 致未成年人</strong></p>
        <p>
          <strong
            >1、我公司重视对未成年人的个人信息保护，如您未满18周岁，请您在填写个人信息时加强个人保护意识并谨慎对待，请您在监护人陪同下仔细阅读，充分理解本隐私政策，并征得监护人的同意后使用我们的服务或向我们提供信息；特别是，如果您为儿童，我们将为儿童提供更高水平的个人信息保护，请儿童及监护人详细查看<a
              href="http://www.58pic.com/?m=links&amp;a=et"
              target="_blank"
              style="
                color: var(--qtd-brand-color-6);
                text-decoration: underline;
              "
              >《利顺儿童个人信息保护规则》</a
            >并由监护人决定是否同意；如您未取得监护人统一的，监护人可以通过联系我公司西华县利顺信息技术有限公司公布的客服联系方式通知我司处理相关账号，我司有权就相关账号包括但不限于浏览、发布信息、互动交流在内的功能、使用进行限制。</strong
          >
        </p>
        <p><strong>2、特别提示</strong></p>
        <p>
          （1）未成年人使用利顺网相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯；
        </p>
        <p>（2）青少年用户必须遵守《全国青少年网络文明公约》；</p>
        <p>（3）要善于网上学习，不浏览不良信息；</p>
        <p>（4）要诚实友好交流，不侮辱欺诈他人；</p>
        <p>（5）要增强自护意识，不随意约会网友；</p>
        <p>（6）要维护网络安全，不破坏网络秩序；</p>
        <p>（7）要有益身心健康，不沉溺虚拟时空。</p>
        <p><strong># 致监护人</strong></p>
        <p>
          <strong
            >1、为更好地保护未成年人隐私权益，我公司特别提醒您慎重发布包含未成年人素材的内容，一经发布，即视为您已获得权利人同意在利顺网相关服务展示未成年人的肖像、声音等信息，且允许利顺网依据本协议使用、处理该等与未成年人相关的内容。</strong
          >
        </p>
        <p>
          <strong
            >2、如您的被监护人使用利顺网相关服务，您作为监护人应指导并监督被监护人的注册和使用行为，如您的被监护人申请注册利顺网账号，我公司将有权认为其已取得您的同意；</strong
          >
        </p>
        <p>
          <strong
            >3、您的被监护人在使用利顺网相关服务时可能使用购买产品或服务等功能。您作为监护人，请保管好您的支付设备、支付账户及支付密码等，以避免被监护人在未取得您同意的情况下通过您的利顺网账号使用购买等消费功能。</strong
          >
        </p>
        <p>
          <strong
            >未成年人及您的监护人理解并确认，如您在使用利顺网服务过程中存在违反相关法律法规及本协议内容的，则您及您的监护人将依据相关法律规定承担由此产生的全部法律责任。如您为监护人的，您应选择青少年模式使用我公司提供的服务。</strong
          >
        </p>
        <p>名词解释</p>
        <p>除非另有约定，本隐私政策中使用到的名词，通常仅具有以下定义：</p>
        <p>
          1. 个人信息（出自于GB/T 35273-2020《信息安全技术
          个人信息安全规范》）：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本隐私政策中涉及的个人信息包括自然人的基本资料（包括个人姓名、出生年月、性别、住址、联系方式等）、个人身份信息（包括身份证件号码）、个人生物识别信息（包括面部特征）、网络身份标识信息（包括系统账号、IP地址、个人数字证书等）、个人财产信息（包括银行账号、口令、交易和消费记录、虚拟货币、虚拟交易、兑换码等虚拟财产信息）、个人上网记录（包括网站浏览记录）、个人常用设备信息（包括硬件序列号、硬件型号、设备MAC地址、操作系统类型、唯一设备识别码）、个人位置信息（包括大概地理位置、精准定位信息）。我们实际具体收集的个人信息种类以下文描述为准。
        </p>
        <p>
          2. 个人敏感信息（出自于GB/T 35273-2020《信息安全技术
          个人信息安全规范》）：是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本隐私政策中涉及的个人敏感信息包括您的个人财产信息、个人身份信息、个人生物识别信息、网络身份标识信息、精准定位信息、收货地址。我们实际具体收集的个人敏感信息种类以下文涉及为准。
        </p>
        <p>
          3.
          设备：是指可用于访问我们的产品和/或服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。
        </p>
        <p>
          4.
          唯一设备识别码（专属ID或UUID）：是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（如IMEI/android
          ID/IDFA/OpenUDID/GUID/SIM卡IMSI信息等）。唯一设备识别码有多种用途，其中可在不能使用Cookie（例如在移动应用程序中）时用以提供相关图文内容。
        </p>
        <p>
          5.
          IP地址：每台上网的设备都会指定一个编号，称为互联网协议(IP)地址。这些编号通常都是根据地理区域指定的。IP地址通常可用于识别设备连接至互联网时所在的位置。
        </p>
        <p>
          6. SSL：SSL（Secure Socket
          Layer）又称为安全套接层，是在传输通信协议（TCP/IP）上实现的一种安全协议。SSL支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。
        </p>
        <p>
          7.
          Cookie：Cookie是包含字符串的小文件，在您登入和使用网站或其他网络内容时发送、存放在您的计算机、移动设备或其他装置内（通常经过加密）。Cookie同类技术是可用于与Cookie类似用途的其他技术，例如：Web
          Beacon、Proxy、嵌入式脚本等。
        </p>
        <p>
          8.
          账号：当您注册账号并向我们提供一些个人信息，您就可以更好的使用我们的服务。当您访问利顺时，系统会利用这些账号信息对您进行身份验证，以防止未经授权的人员访问您的账号。
        </p>
        <p>
          9.
          匿名化：是指通过对个人信息的技术处理，使得个人信息主体无法被识别或者关联，且处理后的信息不能被复原的过程。
        </p>
        <p>
          10.
          去标识化：是指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别或关联个人信息主体的过程。
        </p>
        <p>
          11.
          服务器日志：通常情况下，我们的服务器会自动记录您在访问网站时所发出的网页请求。这些“服务器日志”通常包括您的网络请求、互联网协议地址、浏览器类型、浏览器语言、请求的日期和时间及可以唯一识别您的浏览器的一个或多个Cookie。
        </p>
        <p>
          本《隐私政策》将帮助您了解我们如何收集、处理和保护您的信息；帮您了解查询、访问、更正、删除、撤回授权个人信息及注销账号的方式，您可通过以下摘要，了解本《隐私政策》的重点内容：
        </p>
        <p>一、我们如何收集和使用您的个人信息</p>
        <p>二、我们如何使用 Cookie 和同类技术</p>
        <p>三、我们如何共享、转让、披露您的个人信息</p>
        <p>四、我们对您个人信息的存储与保护</p>
        <p>五、您的权利</p>
        <p>六、我们如何处理未成年人的个人信息</p>
        <p>七、您的个人信息如何在全球范围转移</p>
        <p>八、本隐私政策的修订和更新</p>
        <p>九、联系我们</p>
        <p>十、争议解决</p>
        <h3>一、我们如何收集和使用您的个人信息</h3>
        <p>
          个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。利顺依据法律法规以及遵循正当、合法、必要的原则而收集和使用您在使用服务过程中主动提供或由于产品和/或服务需要而产生的个人信息。如果我们欲将您的个人信息用于本隐私政策未载明的其它用途，或基于特定目的将收集而来的信息用于其他目的，我们会及时以合理的方式向您告知，并在使用前再次征得您的同意。<br /><strong
            >我们仅会出于本政策所述的</strong
          ><strong>以下场景中</strong
          ><strong>，收集和使用您的个人信息：</strong>
        </p>
        <h4>1、注册账号/快捷登录</h4>
        <p>
          <strong
            >（1）手机号注册/快捷登录：当您使用手机号注册/快捷登录利顺时，您需要向利顺提供手机号，</strong
          >手机号作为您的初始用户名，您可以在您的后台账户管理中进行修改。<a
            name="_Hlk86944706"
          ></a
          ><strong>在此过程中，</strong
          ><strong
            >基于我们与通信运营商的合作，经过您的明示同意，运营商会将您的手机</strong
          ><strong>验证</strong><strong>码发送给我们</strong
          ><strong>，</strong>便于我们为您提供快捷的登录服务；
        </p>
        <p>
          我们收集该类信息是为了：完成注册程序、提供快捷登录功能；为您持续稳定提供专属于注册用户的产品和/或服务；实现您的管理账户，您可以查看您的收藏、会员管理、客户管理等内容，并不会因为设备更换而丢失；保护您的账号安全。
        </p>
        <p>
          <strong
            >您应知悉，手机号码和验证码匹配结果属于您的个人敏感信息，我们收集该类信息是为了满足相关法律法规及政策要求，</strong
          ><strong>您可以拒绝提供，但若</strong
          ><strong
            >您拒绝提供可能导致您无法使用我们的手机号注册/快捷登录功能，进而影响您使用专属于注册用户的部分基础服务，但不影响您以其他方式登陆或以游客身份浏览利顺页面，请您谨慎考虑后再提供。</strong
          >
        </p>
        <p>
          <strong>（2）通过第三方社交账号登录和绑定：</strong
          >您可以使用第三方社交账号（微信、QQ、微博）登录利顺，<strong
            >如果您决定授权上述第三方社交账号登录利顺，第三方平台会同步您的头像、昵称、身份验证信息（</strong
          ><strong>具体以您的授权</strong
          ><strong>第三方社交平台的个人信息</strong><strong>为准</strong
          ><strong>）给我们</strong
          >，您可以在后台账户管理中进行修改，未经您同意不会用作其他用途；
        </p>
        <p>
          我们收集该类信息是为了实现通过第三方社交账号在利顺的登录、并同利顺用户账号进行绑定的功能，<strong>如您拒绝提供或授权第三方社交账号登录，将无法使用第三方社交账登录和绑定利顺账户，</strong>但不影响您直接通过手机号注册/快捷登录。<strong
            >第三方社交账号结合身份验证信息属于您的</strong
          ><strong>个人敏感信息，请您谨慎考虑后再提供。</strong>
        </p>
        <p>
          <strong>（3）帮助您认证成为企业用户：</strong
          >当您想要认证成为企业用户时，您需要提供联系人姓名、联系人手机号码、验证码、企业基本信息（如企业用户名、所属行业、地址、企业注册名称、统一社会信用代码等），以便我们核实企业资质，为您提供符合企业需求的授权服务。您不提供上述信息将无法为您的企业提供商用授权，但不影响其他服务。
        </p>
        <h4>2、商品展示、个性化推荐、发送促销营销信息。</h4>
        <p>
          （1）当您成为利顺的注册用户，在使用我们产品和/或服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求的商品页面展示，我们根据您使用产品和/或服务的具体操作会收集、储存您的一些个人信息，这些信息包括：
        </p>
        <p>
          <strong>【日志信息】：</strong
          >当您使用我们的服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。<strong
            >日志信息包括您的登录账号、访问日期和时间、IP地址、设备信息、网络环境、网络运营商的信息、您访问的浏览记录、您的下载使用记录、操作系统信息软件安装信息、搜索引擎营销信息等您在使用我们的产品或服务时提供、形成或留存的信息。</strong
          >
        </p>
        <p>
          <strong>【设备信息】：</strong
          >我们可能会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备名称、设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境、移动应用列表等软硬件特征信息、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。
        </p>
        <p>
          本项场景/功能涵盖的信息为我们产品和/或服务的基础功能的必要信息，<strong>如您拒绝提供上述信息和/或权限将可能导致您无法使用我们的产品与服务，</strong>但不影响您使用我们为您提供的其他产品和服务<strong>。</strong>请注意，<strong>单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。</strong>这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
        </p>
        <p>
          （2）商品或服务的推送功能非利顺的基础产品和/或服务，仅作为利顺进一步优化用户体验向您额外提供的服务，为了进一步贴合您的服务需求，在此过程中，<strong
            >我们将收集您的浏览及搜索记录、收藏信息、设备信息、位置信息、订单信息、手机号码。</strong
          >
        </p>
        <p>
          我们可能会基于上述一项或几项信息的结合，进行推荐算法建模、程序化广告推荐算法建模、用户行为分析及用户画像，以帮助我们更加了解您如何使用我们的产品与/或服务、维护和改进我们的产品与/或服务或向您提供个性化服务/定向推送，包括我们向您提供相关个性化信息（我们的个性化信息可能会根据信息系统、算法在内的自动化决策机制做出）；我们也可能基于特征标签通过短信、应用内推送向您发送营销信息，提供或推广的商品和服务，包括：功能更新提醒服务、产品/服务新上提醒服务、应用软件和服务及其他社资讯服务；及第三方商品和服务，包括：互联网服务、物流服务、广告服务，以及我们认为可能与您相关的其他商品和服务。
        </p>
        <p>
          如您不同意授权我们收集上述信息，并不会影响您使用利顺的基础产品与服务，如您不希望使用该类个性化推荐服务，<strong>您可以通过以下方式进行自主关闭：您可以通过直接点击推送内容的关闭按钮进行关闭；您可通过短信提示回复退订关闭我们的短信推送</strong>。
        </p>
        <h4>3、分享功能</h4>
        <p>
          当您在使用我们的部分产品和/或服务中时<strong>，除注册登录账户之外，您可能会主动分享交流而产生互动信息</strong>。我们会自动收集您的上述信息，并展示您的昵称、头像、分享的信息内容。特别的，<strong
            >分享功能</strong
          ><strong>基</strong><strong>于您的明确</strong><strong>授权</strong
          ><strong>同意</strong
          ><strong
            >，我们将唤醒您的QQ、QQ空间和微博，您可以自行决定使用您的QQ、微博进行图片和链接的分享，</strong
          ><strong>此功能为我们接入第三方软件开发工具包所开发的功能，</strong
          ><strong
            >如您拒绝唤醒该功能，您将无法使用分享功能，但不影响您其他功能的正常使用</strong
          >。
        </p>
        <p>
          我们收集上述信息以实现所具备的功能，如果您不同意我们收集上述信息，您将无法使用部分产品和/或服务包含的互动功能，但不影响您使用我们为您提供的其他产品和服务。
        </p>
        <p>
          特别希望您注意，<strong>在</strong
          ><strong>互动分享过程中请您妥善保护自己的个人信息，</strong
          ><strong>避免</strong
          ><strong
            >发布您的个人隐私，请您更加谨慎地考虑，是否在使用我们的服务时披露甚至公开分享您的个人信息</strong
          ><strong
            >。如您发现自己的个人信息泄露或您提供的内容涉及他人个人信息或隐私时，尤其是您的账户存在异常，请您立即联系利顺官方客服，以便我们及时采取措施。</strong
          >
        </p>
        <h4>4、商品交易</h4>
        <p>
          仅当您在我们的产品和/或服务中产生积分兑换商品或服务行为时，我们将根据您的兑换行为及兑换的内容收集如下部分或全部的个人信息：<strong
            >交易商品或服务信息、收货人信息（收货人姓名、收货地址及其联系电话）、交易积分、下单时间、订单商户、订单编号、订单状态、支付方式（</strong
          ><strong>此功能为我们接入第三方软件开发工具包所开发的功能</strong
          ><strong>）、支付账号、支付状态。</strong
          >我们收集上述信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客户服务等。
        </p>
        <p>
          <strong>如果您拒绝我们收集上述信息，将无法</strong
          ><strong
            >在我们的产品和/或服务中兑换商品和/或服务，但不影响您使用我们提供的其他产品和/或服务。</strong
          >
        </p>
        <p>
          <strong
            >因您兑换的商品可能系第三方卖家提供，或商品配送可能需要第三方物流完成的，</strong
          >为了顺利、安全、准确向您完成货物的交付及配送，<strong>商家及物流公司在发货及配送环节内不可避免地会获知您的相关配送信息</strong>。我们向您承诺，<strong
            >我们会以最大努力保障您的个人信息安全，我们会严格要求商家及第三方配送公司对您的个人信息保密，只以配送之目的获悉和使用，不得对外泄露或做其他任何用途。</strong
          >
        </p>
        <h4>5、支付功能</h4>
        <p>
          当您在我们客户端上开通服务、订购商品等发生支付行为时，需要用到支付功能。在支付过程中，我们可能会收集您的第三方支付渠道的user
          ID (例如支付宝user ID、微信open ID信息)。<strong
            >上述信息为我们向您提供您购买商品和/或服务所必须的信息，我们无法仅根据该信息获得您的个人财产信息，也不能识别特定自然人身份信息。如果您不同意我们收集上述信息，您将无法使用部分产品和/或服务包含的支付功能，但不影响您使用我们为您提供的其他产品和服务。</strong
          >
        </p>
        <h4>6、搜索功能</h4>
        <p>
          <strong
            >当您在我们的产品中使用搜索功能时，我们需要收集您的一些信息，其中包括如下个人信息：您的设备信息、您的日志信息，包括您搜索的字或词、您的浏览记录和时间、您搜索的时间以及次数。</strong
          >我们收集这些信息是为了向您快速匹配您所需要的内容以及您可能感兴趣的内容，同时亦为了改进我们的产品和服务。
        </p>
        <h4>7、客户服务</h4>
        <p>
          当您向利顺发起投诉、申诉或进行咨询时，为了您的账号与系统安全，我们可能需要您先行提供账号信息，并与您之前的个人信息相匹配以验证您的用户身份。同时，<strong>为了方便与您联系或帮助您解决问题，我们可能还需要您提供姓名、手机号码、电子邮件及其他联系方式等个人信息。另外，我们还会收集您与我们的沟通信息（包括文字/图片/音视频/通话记录形式）、与您的需求相关联的其他必要信息。</strong>我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。
        </p>
        <h4>8、电子签约</h4>
        <p>
          （1）您使用我们的部分产品、服务和/或向我们提供原创作品前，需通过在线电子签约系统完成电子签约，在使用在线电子签约系统时，依据相关法律法规<strong>，我们需要收集您的个人身份信息、个人生物识别信息以及个人财产信息，包括如下：电子合同文本、签署方信息（签署方姓名、住址及其联系电话）、签署方的身份证号码、面部识别特征、银行账户。</strong>此外，我们也可能会再收集一些其他与电子签约相关的信息，包括：<strong>合同期限、签约时间、合同编号、合同状态、支付方式、支付流水号、支付状态</strong>，我们收集这些信息是为了帮助您顺利完成电子签约、保障您的签约安全、查询合同信息、提供合同管理服务等。
        </p>
        <p>
          （2）您使用在线电子签约系统时，为了完成实名认证服务，我们将依据相关法律法规，根据不同的认证方式按需向<strong>在线电子签约系统供应商提供您的个人身份信息、个人生物识别信息以及个人财产信息，包括如下：签署方信息（签署方姓名、联系电话）、签署方的身份证号码、面部识别特征、银行账户</strong>；为了准确验证您的身份，在线电子签约系统供应商需将上述信息提供给合法存有您个人信息的第三方机构（有关政府部门、电信运营商、中国银联、支付宝）进行比对核验。<strong
            >我们向您承诺，我们会以最大努力保障您的个人信息安全，严格要求在线电子签约系统供应商对您的个人信息保密，只以实名认证之目的获悉和使用，不得对无关方泄露或做其他任何用途。</strong
          >
        </p>
        <h4>9、实名认证</h4>
        <p>
          （1）您使用我们的部分产品与/或服务时，需要根据相关法律规定以及我们的指引完成<strong>个人/企业实名认证程序</strong>，您也可自行通过【账户主页-实名认证】页面使用实名认证服务。为了完成实名认证服务，<strong>我们将依据相关法律法规获取您的个人身份信息、个人生物识别信息等，包括如下：姓名、身份证件号码、面部识别特征或或企业证明证件等，如企业营业执照、事业单位法人证书等。</strong>为了准确验证您的身份，我们需将上述信息传输给合法存有您个人信息的第三方机构（有关政府部门）进行比对核验。<strong
            >我们向您承诺，上述信息仅用于实名认证之目的，完成身份核验后将立即自行并要求第三方机构予以删除；但是，为了履行存储注册信息义务，我们将存储您的姓名、身份证件号码、联系方式。</strong
          >
        </p>
        <p>
          （2）您理解并确认，我们会以最大努力保障您的个人信息安全，并严格要求第三方机构对您的个人信息保密，只以实名认证之目的获悉和使用，不得对无关方泄露或做其他任何用途。
        </p>
        <p>
          （3）同时，您需理解<strong>，姓名、身份证件号码、面部识别特征、您手持身份证件的照片属于您的个人敏感信息，</strong>我们收集该类信息是基于实名认证程序要求，如您拒绝提供可能导致您无法登录账号并使用相关产品功能，请您谨慎考虑后再选择是否提供。
        </p>
        <h4>
          10、开展内部数据分析和研究，第三方SDK统计服务，改善我们的产品或服务
        </h4>
        <p>
          （1）为改善我们的产品和服务，经您授权同意我们可能接入第三方产品和服务或接受第三方SDK统计服务，我们将明确标识由第三方提供，告知您收集数据的类型和用途，对于收集到的您的个人信息，必要时我们将建立安全评估机制和采取个人信息安全保护措施，SDK数据将不含与我们提供产品或服务无关的数据。目前我们产品中包含的第三方SDK服务请具体查阅<span
            style="color: windowtext; text-underline: none"
            >《利顺第三方合作目录》<a href="#_msoanchor_2">[N2]</a></span
          >&nbsp; 。<strong
            >前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。为了最大程度保障您的信息安全，我们建议您在使用任何第三方SDK类服务前先行查看其隐私条款。</strong
          >
        </p>
        <p>
          我们将监督第三方接入及第三方SDK统计服务，并对违规收集您个人信息的行为采取技术检测、及时切断接入等有效措施，以保护您的个人信息不被泄露；此外，为保障您的合法权益，如您发现这等SDK或其他类似的应用程序侵权您的个人信息
        </p>
        <p>
          （2）<strong
            >本产品集成友盟U-App SDK终端统计分析、服务友盟U-Push
            SDK消息推送服务和友盟厂商推送通道SDK（如小米、华为、oppo、vivo、魅族等）</strong
          >，需要您授权友盟SDK提供对应的服务；在您授权后，友盟SDK将收集如下相关的个人信息。设备Mac地址、唯一设备识别码（IMEI/android
          ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
          信息）<strong>以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力，以及用于唯一标识设备，以便向目标设备推送消息</strong>。采集地理位置甄别推送通道，提高消息推送的区域覆盖率。详见友盟官网（https://www.umeng.com/）上的隐私政策
          （https://www.umeng.com/page/policy）条款。
        </p>
        <p>
          除上述个人信息外，统计分析服务将根据统计维度的不同采集如下信息：SDK/API/JS代码版本、浏览器、互联网服务提供商、平台、时间戳、应用标识符、应用程序版本、应用分发渠道、独立设备标识符、设备型号、传感器参数、终端制造厂商、终端设备操作系统版本、会话启动/停止时间、语言所在地、时区和网络状态（WiFi等）、硬盘、CPU和电池使用情况等。
        </p>
        <p>
          （3）本产品/服务为改善注册及登陆页面用户体验，集成第三方SDK一键登录服务：闪验SDK，用于帮助实现用户一键登录行为。为了实现网关取号技术，闪验SDK需要获取IP地址、网卡（MAC）地址、
          国际移动设备识别码（IMEI）、OAID（替代IMEI）、sim卡信息，并会收集手机机型、系统类型、系统版本、网络环境、网关取号报错日志等数据以提供统计分析服务能力，并可提供反诈等功能。（注：您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您注销账号时，我们将根据适用法律法规的要求尽快删除或匿名化您的个人信息。）
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>关于特定功能权限的提示：</strong></p>
        <p>
          您在使用利顺产品/服务时，除非经您的明示授权同意，<strong>我们不会默认开启您的无限局域网/蜂窝网络、相册、摄像头、消息通知等权限。</strong>如您需要关闭您为使用某项功能而开启的权限，大多数移动设备都会支持您的需求，具体方法请联系您的移动设备的服务商或提供商，<strong
            >请您注意，您开启某项权限即代表您授权我们收集您的相关信息来为您提供对应的服务，一旦您关闭即代表您取消了授权，我们将不再基于对应权限继续收集相关信息，但这将导致我们产品/服务的某项功能无法实现。</strong
          >
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>关于个人敏感信息的提示</strong></p>
        <p>
          <strong
            >个人敏感信息是一旦泄露、非法提供或滥用可能危害人身和财务安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息<a
              name="_Hlk86943800"
            ></a
            >，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息<a
              name="_Hlk86944343"
            ></a
            >，以及不满十四周岁未成年人的个人信息。相关由您提供或我们收集您的信息中，可能包含您的敏感信息，我们会采取严格和特殊加密措施保护您的个人信息，并要求合作第三方对您的个人信息进行严格保密，未经您明示授权，我们不会对外披露您的个人敏感信息或用作其他用途。</strong
          >请您谨慎并留意个人敏感信息，<strong
            >您同意您的个人敏感信息我们可以按照本政策所述的目的和方式来处理。</strong
          >
        </p>
        <h3>二、我们如何使用 Cookie 和同类技术</h3>
        <h4>（一）Cookie</h4>
        <p>
          （1）在您使用我们的产品与/或服务时，我们可能会使用Cookie和同类技术收集您的一些个人信息，包括：您访问网站的习惯、您的浏览信息、您的登录信息，Cookie和同类技术收集该类信息是为了您使用我们的产品与/或服务的必要、简化您重复操作的步骤（如注册、登录）、便于您查看使用历史（如视频观看历史）、向您提供更切合您个人需要的服务内容和您可能更感兴趣的内容、保护您的信息和账号安全性、提升我们的产品和服务等。
        </p>
        <p>
          （2）我们不会将 Cookie
          用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
          Cookie。您可以清除计算机上保存的所有
          Cookie，大部分网络浏览器都设有阻止 Cookie
          的功能。如果您拒绝我们使用Cookie及同类技术收集和使用您的相关信息，您可在浏览器具备该功能的前提下，通过您的浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在您的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪您的个人信息。您如需详细了解如何更改浏览器设置，请具体查看您使用的浏览器的相关设置页面。您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如您拒绝使用或删除的，您可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对您的信息保护和账号安全性造成一定的影响。
        </p>
        <h4>（二）网站信标和像素标签</h4>
        <p>
          除 Cookie
          外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击
          URL。
          如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。
          借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        </p>
        <h4>（三）Do Not Track（请勿追踪）</h4>
        <p>
          很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not
          Track
          请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
          Do Not Track，那么我们的所有网站都会尊重您的选择。
        </p>
        <h3>三、我们如何共享、转移、公开披露您的个人信息</h3>
        <h4>（一）共享</h4>
        <p>
          <strong
            >我们不会向其他任何公司、组织和个人分享您的个人信息，但以下情况除外：</strong
          >
        </p>
        <p>
          1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。<br />
          &nbsp;&nbsp;&nbsp;
          2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。<br /><strong
            >&nbsp;&nbsp;&nbsp; 3</strong
          ><strong>、与我们的关联公司共享</strong
          >：您的个人信息可能会与我们关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。<br />&nbsp;<strong
            >&nbsp;&nbsp;4</strong
          ><strong>、与授权合作伙伴共享：</strong
          >仅为实现本隐私政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。<strong>对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。如果您拒绝我们的合作伙伴在提供服务时收集为提供服务所必须的个人信息，将可能导致您无法在我们的平台中使用该第三方服务。</strong>通常我们的合作伙伴有如下几类：1）我们聘请来提供第三方数据统计和分析服务的公司可能需要采集和访问个人数据以进行数据统计和分析。在这种情况下，这些公司
          必须遵守我们的数据隐私和安全要求。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。2）<strong>我们平台的第三方商家：为实现您积分兑换产品或服务的需求（包括享受售后服务），我们会将您的订单信息与交易相关的信息共享给我们平台的第三方商家。</strong>例如，当您在利顺积分商城兑换产品时，我们会向该商家提供您的必要信息，以便向您寄送产品。3）委托我们进行信息推广和广告投放的合作伙伴：您授权我们有权与委托我们进行信息推广和广告投放的合作伙伴共享我们使用您的相关信息形成的无法识别您的个人身份的间接用户画像、设备信息、去标识化或匿名化处理后的分析/统计类信息，以帮助其在不识别您个人身份的前提下进行广告或决策建议、提高广告有效触达率、衡量广告和相关服务的有效性。4）为我们的产品和/或服务提供功能支持的服务提供商：包括：提供作品支持的合作方、提供支付服务的支付机构、提供一键登录服务的运营商、第三方配送公司和其他服务提供商，我们共享信息的目的是可以实现我们产品和/或服务的功能，让您可以正常使用。
        </p>
        <h4>（二）转移</h4>
        <p>
          1、在获取明确同意的情况下转移：获得您的明确同意后，我们会向其他方转移您的个人信息；<br />
          &nbsp;&nbsp;&nbsp;
          2、在涉及合并、收购或破产清算时，如涉及到个人信息转移，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <h4>（三）公开披露</h4>
        <p>
          我们仅会在以下情况下，公开披露您的个人信息：<br />1、获得您明确同意后；<br />2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        </p>
        <p><strong>四、我们对您个人信息的存储与保护</strong></p>
        <p><strong>1</strong><strong>、信息存储</strong></p>
        <p>
          （1）存储地点：我们依照法律法规的规定，仅会将收集到的有关您的个人信息存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人信息或向境外提供个人信息的场景。如果确有必要进行数据的跨境传输，我们会单独向您明确告知（包括数据出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等），并征得您的授权同意，我们会确保数据接收方有充足的数据保护能力来保护您的个人信息。
        </p>
        <p>
          （2）
          存储期限：我们在为您提供满足产品和服务目的所必需且最短的期间内保留您的个人信息，例如：当您使用我们的注册及会员功能时，我们需要收集您的手机号码，且在您提供后并在您使用该功能期间，我们需要持续保存您的手机号码，以向您正常提供该功能、保障您的账号和系统安全。此外，我们或对您的相关信息保存至相关法律所规定的必要期限（例如《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。
        </p>
        <p>
          （3）在超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。
        </p>
        <p><strong>2</strong><strong>、信息保护</strong></p>
        <p>
          （1）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。
          我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如信用卡信息）时受
          SSL 加密保护；
          我们同时对我们网站提供安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；
          我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
        </p>
        <p>
          （2）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
        </p>
        <p>
          （3）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。
        </p>
        <p>
          <strong
            >（4）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。</strong
          >如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。同时，我们还有专业的安全技术和运营团队负责维护，便于及时有效的响应、处置各类安全漏洞和突发事件，联合相关部门进行安全事件溯源和打击。
        </p>
        <p>
          （5）<strong>发生或者可能发生个人信息泄露、篡改、丢失的，我们将立即采取补救措施，并第一时间通知相关部门、负责人及信息主体、监管部门。</strong>通知内容包括：1）所泄露、篡改、丢失的信息种类、原因和可能造成的危害；2）我们所采取的补救措施和个人可以采取的减轻危害的措施；3）我们个人信息处理相关负责人的联系方式。同时，我们将及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。<strong
            >我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。</strong
          >
        </p>
        <h3>五、您的权利</h3>
        <p>
          按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：
        </p>
        <h4>（一）访问您的个人信息</h4>
        <p>
          您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：<br />
          &nbsp;&nbsp;&nbsp;
          账户信息——如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户等，您可以随时在前往个人中心、联系在线客服或通过发送电子邮件至a1043263349@foxmail.com。我们将在15个工作日内回复您的访问请求。<br />&nbsp;&nbsp;&nbsp;<strong
            >对于您在使用我们的产品或服务过程中产生的其他个人信息，如果您想行使数据访问权，请联系在线客服发送电子邮件至a1043263349@foxmail.com并依据客服提供的要求进行身份验证，您已知悉，为配合您调取相关数据我们可能会向您收取一定的费用。</strong
          >
        </p>
        <h4>（二）更正您的个人信息</h4>
        <p>
          当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。
        </p>
        <h4>（三）删除您的个人信息</h4>
        <p>
          在以下情形中，您可以通过联系在线客服或发送电子邮件至a1043263349@foxmail.com向我们提出删除个人信息的请求：<br />1、如果我们处理个人信息的行为违反法律法规；<br />2、如果我们收集、使用您的个人信息，却未征得您的同意；<br />3、如果我们处理个人信息的行为违反了与您的约定；<br />4、如果您不再使用我们的产品或服务，或您注销了账号；<br />5、如果我们不再为您提供产品或服务。
        </p>
        <p>
          若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
          当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        </p>
        <h4>（四）改变您授权同意的范围或撤回您的授权</h4>
        <p>
          每个业务功能需要一些基本的个人信息才能得以完成（见本策略“第一部分”）。对于额外收集的个人信息的收集和使用，您可以随时给予或撤回您的授权同意。您可以通过联系在线客服或通过发送电子邮件至a1043263349@foxmail.com的方式提出更正申请。<br />
          &nbsp;&nbsp;&nbsp;
          当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <h4>（五）个人信息主体注销账户</h4>
        <p>
          您随时可注销此前注册的账户，您可以通过以下方式自行操作：您可以随时联系在线客服或通过发送电子邮件至a1043263349@foxmail.com。我们将在15个工作日内回复您的访问请求。<br />
          &nbsp;&nbsp;&nbsp;
          在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。<br />
          &nbsp;&nbsp;&nbsp;
          在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的个人信息副本传输给您指定的第三方。
        </p>
        <h4>（六）去世用户的个人信息保护</h4>
        <p>
          （1）利顺将从2021年11月1日起，根据《中华人民共和国个人信息保护法》的相关规定升级死者个人信息保护制度。利顺用户（仅限自然人）去世后，其近亲属为了自身的合法、正当利益，可以通过本隐私政策第九条“联系我们”中公示的联系方式，对去世用户的相关个人信息行使查阅、复制、更正、删除等权利，但是去世用户生前另有安排的除外。
        </p>
        <p>
          （2）您理解并确认，为了充分保护去世用户的个人信息权益，申请行使本条权利的去世用户近亲属需要根据利顺的指定流程或客服提示，提交去世用户的身份证明文件、死亡证明文件、申请人的身份证明文件、申请人与去世用户的亲属关系证明文件，并提供申请行使的权利种类、目的。更多关于去世用户的个人信息保护流程、条件等事项请与我们在线客服联系。
        </p>
        <h4>（七）约束信息系统自动决策</h4>
        <p>
          在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。
        </p>
        <h4>（八）响应您的上述请求</h4>
        <p>
          为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。<br />
          &nbsp;&nbsp;&nbsp;
          我们将在十五个工作日内做出答复。如您不满意，还可以通过以下途径投诉：<br />
          <br />
          &nbsp;&nbsp;&nbsp;
          对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。<br />
          &nbsp;&nbsp;&nbsp;
          在以下情形中，按照法律法规要求，我们将无法响应您的请求：<br />1、与国家安全、国防安全有关的；<br />2、与公共安全、公共卫生、重大公共利益有关的；<br />3、与犯罪侦查、起诉和审判等有关的；<br />4、有充分证据表明您存在主观恶意或滥用权利的；<br />5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
        </p>
        <h3>六、我们如何处理儿童的个人信息</h3>
        <p>
          我们的产品、网站和服务主要面向成人。<strong
            >如果没有父母或监护人的同意，儿童不得创建自己的用户账户。</strong
          ><br />
          &nbsp;&nbsp;&nbsp;
          对于经父母、监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。<br />
          尽管当地法律和习俗对儿童的定义不同，但我们将不满
          14周岁的任何人均视为儿童。<br />
          &nbsp;&nbsp;&nbsp;
          如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
        </p>
        <p><strong>未成年人特别提示</strong></p>
        <p>
          （1）未成年人使用利顺网相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯；
        </p>
        <p>（2）青少年用户必须遵守《全国青少年网络文明公约》；</p>
        <p>（3）要善于网上学习，不浏览不良信息；</p>
        <p>（4）要诚实友好交流，不侮辱欺诈他人；</p>
        <p>（5）要增强自护意识，不随意约会网友；</p>
        <p>（6）要维护网络安全，不破坏网络秩序；</p>
        <p>（7）要有益身心健康，不沉溺虚拟时空。</p>
        <h3>七、您的个人信息如何在全球范围转移</h3>
        <p>
          原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。<br />
          &nbsp;&nbsp;&nbsp;
          由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的个人信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。<br />
          &nbsp;&nbsp;&nbsp;
          此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。<strong
            >在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</strong
          >
        </p>
        <h3>八、本隐私政策如何更新</h3>
        <p>
          我们可能适时会对本隐私政策进行调整或变更，<strong>我们将在隐私政策更新后以网站公告、弹窗方式提醒您详细阅读并依据您的意愿选择是否同意适用新的隐私政策。除法律法规或监管规定另有强制性规定外，经调整或变更的内容一经通知或公布后生效</strong>。<strong
            >如您在隐私政策调整或变更后需继续使用我们提供的服务的，您应充分理解并同意相关隐私政策，如您不接受更新的隐私政策的，您或许无法享受完整服务，但您仍可继续使用利顺网提供的浏览、查看等基本服务。</strong
          >
        </p>
        <p><strong>九、争议解决</strong></p>
        <p>
          有关本《隐私政策》的解释及争议解决均应适用中华人民共和国法律。如就本政策的签订、履行等发生任何争议的，双方应尽量友好协商解决<strong
            >；协商不成时，任何一方均可向西华县利顺信息技术有限公司住所地享有管辖权的人民法院提起诉讼。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p style="line-height: 33px; page-break-before: always">
          <strong>附件一《利顺儿童个人信息保护规则》</strong>
        </p>
        <p>
          为了保护儿童个人信息安全，促进儿童健康成长。本规则<strong
            >旨在更详细的向监护人（以下也称“您”）和儿童说明我们如何收集、使用、存储和处理儿童个人信息，以及我们为您与儿童提供的访问、更正、删除、保护这些信息的方式。如果您是儿童用户的监护人，请您在充分理解并同意本规则后再使用我们的产品和/或服务。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>一.适用范围</strong></p>
        <p>
          本规则<strong
            >仅适用于在中华人民共和国境内通过网络从事收集、存储、使用、转移、披露儿童个人信息等活动。我们的产品及服务主要面向成年人，因此未成年人不得在未经监护人的许可下注册和登录用户账号。</strong
          >
        </p>
        <p>
          我们将本着正当必要、本人及监护人知情同意、目的明确、安全保障、依法利用的原则收集、存储、使用、转移、披露儿童个人信息，指定本规则。《隐私政策》展示了我们收集、使用、处理、保护个人信息的一般做法，<strong
            >本规则为《隐私政策》的一部分；如在儿童个人信息保护规则方面，本规则与《隐私政策》正文存在不一致的，本规则优先适用，如本规则中未提及的，则视产品实际情况适用/参照适用《隐私政策》，本规则中涉及的词语含义与《隐私政策》正文中的一致。</strong
          >
        </p>
        <p><strong>&nbsp;</strong></p>
        <p><strong>给监护人的特别说明：</strong></p>
        <p>
          当我们获悉您的孩子不满十四周岁，<strong>我们将根据本政策采取特殊措施保护我们获得的您孩子的个人信息，监护人比我们对您的权益保护更为便利，请帮助我们共同保护您孩子的个人信息和隐私安全，</strong>要求他们在您的监护下共同阅读本政策，在您的支持同意下使用我们的产品和/或服务。<strong
            >如果您不同意《隐私政策》及本规则，或任何与注册获取用户账号相关的协议。请您立即要求您的儿童停止访问利顺，您同意本规则（包括更新规则）或者您在阅读本规则下同意您的儿童继续使用我们的产品和/或服务、提交个人信息，都表示您同意我们按照本规则收集、存储、使用、转移、披露您儿童个人信息。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>给儿童的特别说明：</strong></p>
        <p>
          <strong
            >如果您不满14周岁，请您在通知您的监护人且监护人明确同意本规则之前，不要使用我们的产品和/或服务。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>二.我们收集和使用儿童个人信息的规则</strong></p>
        <p>
          1、我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则收集和使用儿童个人信息，<strong>不会收集与我们提供的产品和/或服务无关的儿童个人信息</strong>，不会违反法律规定和我们与您的约定收集、使用儿童个人信息。
        </p>
        <p>
          <strong>2</strong
          ><strong
            >、我们没有独立的向儿童提供的产品和/或服务，一般情况下，我们收集和使用的儿童个人信息仅发生在《隐私政策》第二部分我们如何收集和使用您的信息，在《隐私政策》指引下，我们一般情况无法主动获取账号使用者的年龄（除非您在个人账户信息中填写）或者确认实际使用人账户信息的真实性，此情况下适用《隐私政策》。一旦我们发现您是儿童，我们会对您的账户信息予以更高程度的保护。<a
              name="_Hlk86947749"
            ></a
            >除《隐私政策》第二部分外，我们还会收集您与儿童之间监护关系的信息。</strong
          >
        </p>
        <p>
          <strong>3</strong
          ><strong
            >、我们再次以显著、清晰的方式告知儿童监护人，并应当征得儿童监护人的同意，我们可能会收集和使用的儿童的个人信息的规则请您查阅《隐私政策》正文第二章节</strong
          >，因业务需要，当确需超出前述约定的目的、范围收集、使用您监护的儿童的个人信息的，我们会再次征得您的同意。
        </p>
        <p>&nbsp;</p>
        <p><strong>三.我们共享、转让、公开披露儿童个人信息的规则</strong></p>
        <p><strong>(</strong><strong>一)共享</strong></p>
        <p>
          对于儿童个人信息，我<strong>们仅在本规则所述的目的和范围内或依照法律要求进行共享，除非存在以下一种或多种情形</strong>：
        </p>
        <p><strong>1、</strong><strong>与作为监护人的您之间的共享；</strong></p>
        <p>2、事先已征得您的明确授权同意；</p>
        <p>
          3、如果我们需要改变儿童个人信息的处理目的时，将再次征求您的授权同意。
        </p>
        <p>
          <strong>4、</strong
          ><strong
            >如我们需要委托服务商处理儿童个人信息的，请您放心，我们会对服务商和其服务行为等进行安全评估，签署协议，明确责任、处理事项、处理期限、处理性质和目的等，要求其处理行为不得超出我们的授权范围；</strong
          >
        </p>
        <p>5、有权机关的要求、法律法规等规定的其他情形。</p>
        <p>&nbsp;</p>
        <p><strong>(</strong><strong>二) 转移与公开披露</strong></p>
        <p>
          我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则转移、公开披露儿童个人信息。<strong
            >如您希望了解更多，请您查阅《隐私政策》正文第三条。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>四、您和/或儿童对儿童个人信息的相关权利</strong></p>
        <p>
          我们的产品和服务为您和儿童提供了多种对儿童个人信息行使相关权利的功能，您可以通过这些功能管理儿童个人信息和儿童的网络生活，包括：
        </p>
        <p><strong>(</strong><strong>一)访问权</strong></p>
        <p>
          您或儿童<strong>可以在应用程序及WEB端查询和访问儿童的相关个人信息</strong>，例如：个人资料信息、视频观看记录、搜索记录、下载记录、收藏记录，详细操作步骤<strong>我们已经在《隐私政策》第五条向儿童及其监护人明确告知，您可以参照《隐私政策》进行操作</strong>。
        </p>
        <p>&nbsp;</p>
        <p><strong>(</strong><strong>二)更正/修改权</strong></p>
        <p>
          <strong>您可以在应用程序及WEB端更正/修改儿童的相关个人信息</strong
          >，例如：个人资料信息；或您可以联系我们解决，<strong
            >我们会在经对您的身份进行验证，且不影响信息的客观性和准确性的情况下进行更正/修改。</strong
          >
        </p>
        <p>
          同时，如您或儿童发现我们收集、存储、使用、披露的<strong>儿童个人信息有错误的，可以联系我们更正，我们会在完成身份验证和核实问题后及时采取措施予以更正</strong>。
        </p>
        <p>&nbsp;</p>
        <p><strong>(</strong><strong>三)删除权</strong></p>
        <p>
          <strong>1</strong
          ><strong
            >、您和儿童可以在应用程序及WEB端自行删除儿童的相关个人信息，详细操作步骤我们已经在《隐私政策》第五条向儿童及其监护人明确告知，</strong
          >您可以参照《隐私政策》进行操作，<strong>上述信息删除或导致您账户下的部分数据丢失，可能导致无法继续使用我们部分产品和/或服务</strong>。
        </p>
        <p>
          2、在以下情形下，您或儿童可以直接向我们提出删除儿童个人信息的请求，我<strong>们会在完成身份验证和核实问题后及时采取措施予以删除</strong>，包括：
        </p>
        <p>
          （1）我们违反法律法规规定或我们与监护人的约定收集、存储、使用、转移、披露儿童个人信息的；
        </p>
        <p>
          （2）我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息的；
        </p>
        <p>（3）监护人撤回同意的；</p>
        <p>（4）监护人或儿童通过注销等方式终止使用的产品或服务的；</p>
        <p>（5）法律法规等规定的其他情形。</p>
        <p>&nbsp;</p>
        <p>
          <strong>(</strong><strong>四)提前获知产品和/或服务停止运营权</strong>
        </p>
        <p>
          我们将持续为您提供优质服务，若因特殊原因导致我们的部分或全部产品和/或服务被迫停止运营，我们将提前在显著位置或向您发送推送消息或以其他方式通知您，并<strong
            >将停止对儿童个人信息的收集，删除或匿名化处理我们持有的儿童个人信息。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>五、我们存储和保护儿童个人信息的规则</strong></p>
        <p>
          我们依照法律法规的规定，<strong
            >将儿童个人信息存储于中华人民共和国境内。我们仅在为提供我们的产品和服务之目的所必需且最短的期间内保留儿童个人信息。在超出上述存储期限后，我们会对儿童个人信息进行删除或匿名化处理。</strong
          >
        </p>
        <p>
          我们采用了<strong>符合行业标准的安全技术措施及配套的组织架构和管理体系</strong>等多层面保护措施来保护儿童个人信息；同时，我们<strong>遵循最小授权原则</strong>，对工作人员设定了严格的信息访问权限，控制儿童个人信息知悉范围；我们也采取了必要技术措施，避免违法复制、下载儿童个人信息。<strong
            >如您希望了解更多，请您查阅《隐私政策》正文第六条。</strong
          >
        </p>
        <p>&nbsp;</p>
        <p><strong>六、联系我们</strong></p>
        <p>
          如您对本规则的执行或使用我们的产品和/或服务时遇到的与儿童隐私保护相关的事宜有任何问题（包括问题咨询、投诉等），您可以通过以下任意方式与我们联系：
        </p>
        <p><strong>我们的主体信息：西华县利顺信息技术有限公司</strong></p>
        <p><strong>我们的邮箱：a1043263349@foxmail.com</strong></p>
        <p>
          我们有专门人员负责对接；<strong>您也可以通过《隐私政策》中提供的其他反馈通道与我们联系，</strong>我们会尽最大努力为您提供满意的解决方案，并在15个工作日内为您处理完毕。
        </p>
      </div>
    </div>
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.main {
  background-color: #f6f6f6;
}
.content {
  width: 90%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 6px;
  margin: 30px auto;
}
.content h2 {
  font-size: 20px;
  color: #333;
  text-align: center;
}
.content h3 {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content h4 {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content .text-b {
  font-weight: 700;
}

.content p {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-bottom: 20px;
}

.content p i {
  font-weight: 400;
  text-align: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #9a9a9a;
  display: inline-block;
  line-height: 16px;
  border-radius: 50%;
  border: 1px solid #9a9a9a;
  margin-right: 8px;
  vertical-align: 1px;
}
</style>