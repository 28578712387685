<template>
  <div class="main">
    <Navbar></Navbar>
    <div class="content">
      <h2>售后服务</h2>
        <p>更新时间：2024年 07 月 01 日<br />生效时间：2024年 07 月 01 日</p>
        <h3>售后服务</h3>
        <p>
          邮箱：<span class="text-green">a1043263349@foxmail.com</span
          >（工作人员会在3个工作日内完成核实并处理）
        </p>
        <br />
        <h3>售后流程</h3>
        <p><i>1</i>提供利顺ID（用户后台右上角数字）</p>
        <p><i>2</i>提供充值记录</p>
        <p><i>3</i>提供问题原因和联系方式</p>
        <br />
        <h3>提示</h3>
        <p>
          请在工作时间段内联系，具体：周一至周五：<span class="text-b"
            >9:00-18:00</span
          >（12:00-13:00及法定节假日除外）
        </p>
        <p>工作时间外，请发送投诉邮件，我们会尽快处理！</p>
        <br />
        <h3>退款条件</h3>
        <p>
          若您已使用下载服务，因提供下载服务的特殊性，一经下载文件将会存储于您的电脑，代表您已经使用该服务，除无法下载导致您购买的会员不可使用的情况外，一律不接受退款申请。
        </p>
    </div>
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.main {
  background-color: #f6f6f6;
}
.content {
  width: 90%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 6px;
  margin: 30px auto;
}
.content h2 {
  font-size: 20px;
  color: #333;
  text-align: center;
}
.content h3 {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content h4 {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content .text-b {
  font-weight: 700;
}

.content p {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-bottom: 20px;
}

.content p i {
  font-weight: 400;
  text-align: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #9a9a9a;
  display: inline-block;
  line-height: 16px;
  border-radius: 50%;
  border: 1px solid #9a9a9a;
  margin-right: 8px;
  vertical-align: 1px;
}

</style>