<template>
  <div class="nav_main">
    <div class="top_box">
      <div class="top_box_left">
        <div class="top_logo" @click="goHome()">
          <img src="../assets/logo.png" alt="" />
        </div>
        <div class="top_center_box">
          <div
            v-for="(item, index) in navList"
            :key="index"
            class="nav_text"
            :class="item.id == navIndex ? 'nav_text1' : ''"
            @click="changeNav(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="top_right_box" v-if="!user">
        <img src="../assets/login1.png" class="login1" />
        <div class="login_text" @click="showLogin = true">登录/注册</div>
      </div>
      <div class="info_tx" v-else @click="goUser()">
        <img :src="userInfo.avatar" />
        {{ userInfo.nickname }}
        <div class="logout_text" @click.stop="logout">退出</div>
      </div>
    </div>
    <Login v-if="showLogin == true" @changeLogin="changeLogin"></Login>
    <img  class="kefu" @click="openKefu" src="../assets/service.png" alt="" />
  </div>
</template>

<script>
import Login from "../components/Login.vue";
import axios from "axios";
export default {
  components: {
    Login,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      navList: "",
      navIndex: 0,
      id: 1,
      showLogin: false,
      userInfo: "",
      user: false,
      config: "",
      isNavigating: false,
    };
  },
  mounted() {
    let that = this;
    axios
      .get("https://shop.renguokeji.com/app/v1/category")
      .then(function (response) {
        that.navList = response.data.data;
        console.log(that.navList);
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get("https://shop.renguokeji.com/app/v1/config")
      .then(function (response) {
        that.config = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    if (this.$route.params.id) {
      this.navIndex = this.$route.params.id;
    }
    const value = localStorage.getItem("userInfo");
    if (value) {
      this.userInfo = JSON.parse(value);
      this.user = true;
    }
    if (this.$route.name === 'about' || this.$route.name === 'ImgInfo') {
        const cachedNavIndex = localStorage.getItem("navIndex");
        if (cachedNavIndex) {
            this.navIndex = parseInt(cachedNavIndex);
        }
    }
  },
  methods: {
    changeNav(item, index) {
      // if (this.$route.params.id) {
      //   this.navIndex = item.id;
      //   const valueToSend = { id: item.id, name: item.name };
      //   this.$emit("send", valueToSend);
      // } else {
      //   this.$router.push({
      //     name: "about",
      //     params: { id: item.id, name: item.name },
      //   });
      // }
      if (this.$route.params.id !== item.id && !this.isNavigating) {
        this.isNavigating = true;
        this.navIndex = item.id;
        localStorage.setItem("navIndex", item.id);
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
        const valueToSend = { id: item.id, name: item.name };
        this.$emit("send", valueToSend);
        this.$router.push({
          name: "about",
          params: { id: item.id, name: item.name },
        }).then(() => {
          this.isNavigating = false;
        }).catch(err => {
          this.isNavigating = false;
        });
      }
      // this.navIndex=index
    },
    goHome() {
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
    },
    goUser() {
      if (this.$route.name !== "User") {
        this.$router.push({ name: "User" });
      }
    },
    changeLogin() {
      this.showLogin = false;
      // location.reload();
    },
    logout() {
      this.user = false;
      this.userInfo = "";
      localStorage.removeItem("userInfo");
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
      alert("退出成功！");
    },
    openKefu(){
			window.open('https://kf.renguokeji.com/kefu/?app=lishun&amp;extra=eyJpZCI6IjljMjA3Yjk1Y2FhOWUzZWE2NmVlYmIxZjNmNDk0ZjE3IiwidXNlcl9pZCI6IjljMjA3Yjk1Y2FhOWUzZWE2NmVlYmIxZjNmNDk0ZjE3IiwiZGV2aWNlX2lkIjoiOWMyMDdiOTVjYWE5ZTNlYTY2ZWViYjFmM2Y0OTRmMTciLCJwbGF0Zm9ybSI6ImFuZHJvaW');
		},
    changeUser() {
      const value = localStorage.getItem("userInfo");
      this.userInfo = JSON.parse(value);
      this.user = true;
    },
  },
};
</script>

<style scoped>
.nav_main {
  position: sticky;
  top: 0;
  z-index: 999;
}
.top_box {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 6em;
  box-shadow: 0 0 3px #ddd;
}
.top_box_left {
  display: flex;
  align-items: center;
}
.top_logo {
  line-height: 0;
}
.top_logo img {
  width: 80px;
  cursor: pointer;
}
.top_right_box {
  position: relative;
  width: 95px;
  height: 30px;
  cursor: pointer;
}
.login1 {
  position: absolute;
  top: 0;
  width: 95px;
  height: 30px;
}
.login_text {
  position: absolute;
  top: 0;
  top: 5px;
  left: 16px;
  font-size: 14px;
  color: #ffffff;
}
.top_center_box {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666666;
  margin-left: 30px;
  cursor: pointer;
}
.nav_text {
  margin-right: 18px;
  transition: all 0.3s ease-in-out;
}
.nav_text:hover {
  color: #000;
  font-weight: bold;
}
.nav_text1 {
  font-size: 16px;
  color: #000;
  font-weight: bold;
  border-bottom: 2px solid;
}
.info_tx {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #999999;
  cursor: pointer;
}
.info_tx img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
}
.logout_text {
  cursor: pointer;
  color: #999;
  margin-left: 10px;
  font-size: 12px;
  transition: all 0.3s ease-in-out;
}
.logout_text:hover {
  color: #f54b4b;
}
.kefu {
  height: 90px;
  position: fixed;
  bottom: 30vh;
  right: -23px;
  cursor: pointer;
}
</style>
