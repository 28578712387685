<template>
  <div class="box">
    <Navbar></Navbar>
    <div class="box1">
        <div style="display: flex;align-items: center;justify-content: space-between;width: 64%;margin: 0 auto;padding: 30px 0;">
            <div style="font-size: 14px;display: flex;align-items: center;margin-top: 48px;">
                <img :src="userInfo.avatar" style="width: 70px;height: 70px;border-radius: 50%;margin-right: 10px;" />
                <div>
                    <div style="font-size: 16px;">{{ userInfo.username }}</div>
                    <div style="color: #999999;font-size: 14px;margin: 3px 0 0 3px;">ID：{{ userInfo.id }}</div>
                </div>
            </div>
            <img src="../assets/vip_icon.png" style="width: 600px;height: 120px;" v-if="titleIndex!=2" />
            <div v-if="titleIndex==2" style="display: flex;align-items: center;justify-content: space-around;width: 320px;height: 70px;background: #333;border-radius: 20px;transform: translate(10px,-10px);">
                <div style="color: #FFFFFF;font-size: 12px;">当前可用余额</div>
                <div style="display: flex;align-items: center;justify-content: space-around;background: #F4F4F4;border-radius: 10px;width: 200px;height: 46px;">
                    <div style="font-size: 14px;font-weight: bold;font-family: 'AaHouDiHei-Regular';">¥120</div>
                    <div style="color: #78DA2F;background: #333333;border-radius: 12px;padding: 5px 12px;font-size: 14px;">充值</div>
                </div>
            </div>
        </div>
        <div class="info_box">
            <div style="display: flex;align-items: center;justify-content: center;padding-top: 25px;">
                <div class="info_title" v-for="(item,i) in titleList" :key="i" @click="changeInfo(i)">
                    <div class="title_text" :class="{'title_text1':i==titleIndex}">{{ item }}</div>
                    <div class="title_icon" v-if="i==titleIndex"></div>
                </div>
            </div>
            <div class="img_box" v-if="collectList.length!=0&&dShow==false">
                <div class="hoem_img" :class="{'hoem_img1':(index+1)%4==0}" v-for="(img,index) in collectList" :key="index" @click="goInfo(img)">
                    <img style="width: 100%;height: 100%;object-fit: cover;" :src="img.thumbimageurl" />
                </div>
            </div>
            <img src="../assets/sj.png" v-if="collectList.length==0||dShow==true" style="margin-top: 9%;width: 25.3%;height: 25.3%;margin-bottom: 16vh;" />
        </div>
    </div>

  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import axios from "axios";
export default {
    components: {
        Navbar,
    },
    data() {
	    return{
            userInfo: '',
            titleList: [
                '我的收藏',
                '我的下载',
                // '账户余额'
            ],
            titleIndex: 0,
            collectList: '',
            dShow: false
        }
    },
    mounted(){
        const info = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(info)
        let that = this
        axios.post('https://shop.renguokeji.com/app/v1/collection/list'+'?token='+this.userInfo.token ,{
            page: 1,
            page_size: 36
        })
        .then(function (response) {
            // if(response.data.code==1){
            //     response.data.data.status==0?that.collectShow = false:that.collectShow = true
            // }
            that.collectList=response.data.data.list
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    methods:{
        changeInfo(i){
            this.titleIndex = i
            i==1?this.dShow=true:this.dShow=false
        },
        goInfo(item){
			this.$router.push({ name: 'ImgInfo', params: item})
		}
    }
}
</script>

<style scoped>
.box{
    /* width: 100vw; */
    /* height: 100vh; */
}
.box1{
    background-image: url("../assets/userBg.png");
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.info_box{
    width: 66%;
    /* height: 74vh; */
    margin: 0 auto;
    background: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-align: center;
    box-shadow: 0px 0px 40px 1px rgba(0,0,0,0.06);
}
.info_title{
    margin-right: 35px;
    position: relative;
}
.title_icon{
    position: absolute;
    top: 10px;
	width: 58px;
	height: 10px;
	background: #FFCBBA;
	border-radius: 50%;
}
.title_text{
    position: relative;
    z-index: 999;
    color: #999999;
    font-size: 14px;
}
.title_text1{
    color: #333;
    font-weight: 600;
}
.img_box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 15px;
}
.hoem_img{
    width: 23.6%;
    height: 424px;
    margin-bottom: 20px;
    border-radius: 10px;
    margin-right: 1.8%;
    overflow: hidden;
    cursor: pointer;
}
.hoem_img1{
    margin-right: 0;
}
</style>