<template>
  <div class="main">
    <Navbar></Navbar>
    <div class="content">
        <h2>版权声明</h2>
        <p>更新时间：2023年 06 月 29 日<br />生效时间：2023年 06 月 29 日</p>
        <h3>版权声明</h3>
        <h4>
          利顺根据用户指令提供作品上传、下载以及传播等，向来十分重视网络版权及其他知识产权以及用户权益的保护。
        </h4>
        <p>
          利顺作为网络服务提供者，对非法转载，盗版行为的发生不具备充分的监控能力，如果您认为利顺用户通过利顺提供的信息存储空间所上载、传播的内容侵犯了您的相关权益的，<br />
          请您向利顺发出权利通知，利顺将根据相关法律规定采取措施删除相关内容，您可以向利顺所设立的专门接受版权投诉和侵权通知的邮箱发送通知书。
          本网站所有作品均由本公司或版权所有人授权发布，如果您侵犯了该作品的知识产权，西华县利顺信息技术有限公司有权依据著作权侵权惩罚性赔偿标准或最高达50万元人民币的法定标准要求赔偿，且有权不以本网站发布的作品授权价格作为参考标准。
        </p>
        <h3>申诉需知</h3>
        <p>投诉方应当提供包含下列文件：</p>
        <p>
          <i>1</i
          >权利人的姓名（名称）、联系方式、地址、身份证扫描件（自然人）、企业法人营业执照（单位）、法定代表人身份证明（单位）。
        </p>
        <p>
          <i>2</i
          >要求删除或者断开链接的侵权作品的准确名称和网络地址，以便利顺能够发现并初步审核涉嫌侵权作品。
        </p>
        <p>
          <i>3</i
          >认为构成侵权的初步证明材料，包括但不限于对作品享有版权或依法享有信息网络传播权的权属证明，以及对涉嫌侵权作品侵权事实的举证。
        </p>
        <p>
          <i>4</i
          >通知书需由权利人或其合法授权人亲笔签名，若为单位则需加盖单位公章。
        </p>
        <p>
          <i>5</i
          >权利人应对通知书的真实性负责。若通知书的内容不真实，权利人将承担由此造成的全部法律责任。
        </p>
        <p class="text-b">
          一旦收到符合上述要求之通知，我们将在合理时间内删除涉嫌侵权的作品。如不符合上述条件，我们会请阁下提供相应信息，且暂不采取包括删除等相应措施。
        </p>
        <p>
          在利顺上传作品的用户视为同意利顺就上述情况所采用的相应措施，利顺不因此而承担任何违约责任或其他任何法律责任。利顺在收到上述通知后会发送电子邮件通知上传该作品的用户，对于多次上传涉嫌侵权作品的用户，利顺将有权删除下架并且扣除相应的积分或者VIP奖励；若发现盗图3张以上的用户，则取消其上传资格以及相应的奖励；若发现盗图5张以上的用户，将永久封禁账号等惩罚措施。
        </p>
        <p class="text-b">
          在您发送邮件后，工作人员会尽快处理，由于涉及内容不同，所需周期也不同，请您耐心等待。
        </p>
        <br />
        <h3>版权举报通道</h3>
        <p>邮箱：a1043263349@foxmail.com</p>
        <br />
        <h3>温馨提示：</h3>
        <p>
          网站作品模板内出现的任何摄影图、商品图、字体、人物肖像仅供作参考展示，未经权利人许可不得用于商用，若擅自使用，后果将由使用者自行承担。
        </p>
        <br />
      </div>
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.main {
  background-color: #f6f6f6;
}
.content {
  width: 90%;
  background: #fff;
  padding: 20px 30px;
  border-radius: 6px;
  margin: 30px auto;
}
.content h2 {
  font-size: 20px;
  color: #333;
  text-align: center;
}

.content h3 {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content h4 {
  font-size: 14px;
  font-weight: 700;
  color: #666;
  margin-bottom: 20px;
}

.content .text-b {
  font-weight: 700;
}

.content p {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  margin-bottom: 20px;
}

.content p i {
  font-weight: 400;
  text-align: center;
  width: 18px;
  height: 18px;
  font-size: 12px;
  color: #9a9a9a;
  display: inline-block;
  line-height: 16px;
  border-radius: 50%;
  border: 1px solid #9a9a9a;
  margin-right: 8px;
  vertical-align: 1px;
}

</style>