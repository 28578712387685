<template>
  <div class="hello">
    <Navbar></Navbar>
    <div class="info_box">
    <swiper :options="swiperOption" ref="swiperRef" style="background-color: #feeae2;">
      <swiper-slide class="banner-item"
        ><img src="../assets/infobg_2.png"
      /></swiper-slide>
      <swiper-slide class="banner-item"
        ><img src="../assets/infobg_3.png"
      /></swiper-slide>
      <swiper-slide class="banner-item"
        ><img src="../assets/infobg_4.png"
      /></swiper-slide>
      <!-- Optional controls -->
    <div class="swiper-pagination"  slot="pagination"></div>
    </swiper>
      <div class="banner_box">
        <div class="search_box">
          <div class="search_content">
            <input
              placeholder="输入关键词描述搜索想要的作品"
              v-model="searchValue"
              class="input"
            />
            <img
              src="../assets/search.png"
              class="search_img"
              style="cursor: pointer;"
              @click="search()"
            />
          </div>
        </div>
        <div class="hot_class">
          猜你喜欢：
          <div
            class="hot_class_info"
            v-for="(item, index) in hotList"
            @click="searchValue = item"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="content_box">
        <!-- <div class="home_info"> -->
        <div v-if="!searchShow" class="home_info">
          <div
            class="home_info_box"
            v-for="(item, index) in dataList"
            :key="index"
            :style="{ backgroundImage: 'url(' + item.bg + ')' }"
          >
            <div class="home_title">
              <img :src="item.image" style="width: 250px; height: 70px" />
              <div class="home_title_more" @click="goMore(item)">
                查看更多<img
                  src="../assets/icon7.png"
                  style="
                    width: 10px;
                    height: 8px;
                    margin-right: 36px;
                    margin-left: 4px;
                  "
                />
              </div>
            </div>
            <div class="img_box" :class="item.id == 2 ? 'img_box1' : ''">
              <div
                class="hoem_img"
                :class="{
                  hoem_img2: item.id == 2,
                  hoem_img3: (index + 1) % 6 == 0,
                }"
                v-for="(img, index) in item.posters.slice(0, 6)"
                :key="index"
                @click="goInfo(img)"
              >
                <img
                  style="width: 100%; height: 100%; object-fit: cover"
                  :src="img.thumbimageurl"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="img_box2" v-if="searchList.length != 0" style="width: 86%;margin: 0 auto;padding-bottom: 30px;">
            <div class="hoem_img4" :class="{hoem_img3: (index + 1) % 3 == 0}" v-for="(img, index) in searchList" :key="index"  @click="goInfo(img)">
              <img style="width: 100%; height: 100%; object-fit: fill" :src="img.thumbimageurl" />
            </div>
          </div>
          <img src="../assets/sj.png" v-else style="margin-top: 3%;width: 18%;margin-bottom: 16vh;" />
        </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- <img src="../assets/service.png" class="service_box" /> -->
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      hotList: "",
      dataList: "",
      searchValue: "",
      searchShow: false,
      searchList: "",
      config: "",
      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        loopedSlides: 6,
        speed: 2000,
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      userInfo: ''
    };
  },
  mounted() {
    let that = this;
    const info = localStorage.getItem('userInfo');
    this.userInfo = JSON.parse(info)
    axios
      .get("https://shop.renguokeji.com/app/v1/home"+'?token='+this.userInfo?.token||'')
      .then(function (response) {
        that.dataList = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get("https://shop.renguokeji.com/app/v1/hotKeywords")
      .then(function (response) {
        that.hotList = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get("https://shop.renguokeji.com/app/v1/config")
      .then(function (response) {
        that.config = response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    goInfo(item) {
      this.$router.push({ name: "ImgInfo", params: item });
    },
    goMore(item) {
      this.$router.push({
        name: "about",
        params: { id: item.id, name: item.name },
      });
    },
    search() {
      let that = this;
      if (!this.searchValue) {
        (that.searchShow = false), (that.searchList = "");
      }
      axios
        .post("https://shop.renguokeji.com/app/v1/poster/list", {
          page: 1,
          size: 36,
          keyword: this.searchValue,
        })
        .then(function (response) {
          that.searchList = response.data.data.list;
          (that.searchShow = true), console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.info_box {
  background: linear-gradient(180deg, #fff7f4 0%, #fffaf6 100%);
  text-align: center;
  /* padding-bottom: 40px; */
  /* padding: 0 60px 40px 60px; */
}
.banner_box {
  /* background: linear-gradient(180deg, #feeae2 0%, #fff8f5 100%); */
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.info_bg {
  width: 1300px;
  height: 460px;
}

.content_box {
  /* width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0; */
}

.search_content {
  width: 1200px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-radius: 40px;
  border: 1px solid #a8a8a863;
  /* background: url('../assets/searchbg.png') no-repeat 100%/100%; */
  background: #ffffff;
}

.input {
  border: none;
  outline: none;
  width: 1000px;
  margin-left: 25px;
}

.hot_class {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #999999;
  margin-top: 10px;
  text-align: center;
}

.hot_class_info {
  margin-right: 15px;
  cursor: pointer;
}

.home_info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 auto;
  /* border: 1px solid red; */
}

.home_info_box {
  width: 100%;
  padding: 20px 7% 45px 7%;
}

.home_title {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.home_title_more {
  color: #999999;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  cursor: pointer;
}

.hoem_img {
  width: 250px;
  /* height: 460px; */
  margin-right: 21px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.hoem_img2 {
  width: 23.6%;
  /* height: 236px; */
  margin-right: 1.3%;
}

.hoem_img4 {
  width: 32.6%;
  /* height: 518px; */
  margin-right: 1%;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 25px;
}
.hoem_img3 {
  margin-right: 0;
}
.img_box {
  display: flex;
  align-items: center;
  justify-content: start;
}
.img_box1 {
  flex-wrap: wrap;
  height: 197px;
  overflow: hidden;
}
.img_box2 {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}
.service_box {
  position: fixed;
  top: 80%;
  right: -1.3%;
  width: 150px;
  height: 94px;
}
.swiper-slide img {
  width: 100%;
}
.banner-item {
  line-height: 0;
}
::v-deep .swiper-pagination-bullet-active{
  background-color: #fff;
}
</style>
