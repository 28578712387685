import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import about from '../views/about.vue'
import ImgInfo from '../views/imgInfo.vue'
import Recharge from '../views/recharge.vue'
import User from '../views/user.vue'
import announce from '../views/announce.vue'
import copyright from '../views/copyright.vue'
import customer from '../views/customer.vue'
import privacy from '../views/privacy.vue'
import vip from '../views/vip.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/ImgInfo',
    name: 'ImgInfo',
    component: ImgInfo
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    component: Recharge
  },
  {
    path: '/User',
    name: 'User',
    component: User
  },
  {
    path: '/announce',
    name: 'announce',
    component: announce
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: copyright
  },
  {
    path: '/customer',
    name: 'customer',
    component: customer
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy
  },
  {
    path: '/vip',
    name: 'vip',
    component: vip
  }
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
