<template>
  <div>
    <Navbar @send="receiveFromChild"></Navbar>
    <div class="content">
      <div class="paybanner"><img src="../assets/pay_banner.png" alt=""></div>
      <div class="boxtent">
        <div class="pay_list">
          <img
            class="bgimg"
            src="../assets/bg2.png"
            alt=""
            :class="imgShow == 1 ? 'bgimg1' : ''"
            @click="imgShow = 1"
          />
          <img
            class="bgimg"
            src="../assets/bg3.png"
            alt=""
            :class="imgShow == 2 ? 'bgimg1' : ''"
            @click="imgShow = 2"
          />
          <img
            class="bgimg"
            src="../assets/bg4.png"
            alt=""
            :class="imgShow == 3 ? 'bgimg1' : ''"
            @click="imgShow = 3"
          />
          <img
            class="bgimg"
            src="../assets/bg1.png"
            alt=""
            :class="imgShow == 4 ? 'bgimg1' : ''"
            @click="imgShow = 4"
          />
        </div>
        <div class="centerbox">
          <div class="wxbox">
            <div class="wx-wrap" @click="wxShow = true">
              <div class="wxboxwrap">
                <img class="wx" src="../assets/wx.png" alt="" />
                <div class="text">微信支付</div>
              </div>

              <div class="lxbox">
                <img class="lx" src="../assets/lx.png" alt="" v-if="wxShow" />
                <div
                  style="
                    border: 2px solid #999999;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                  "
                  v-else
                ></div>
              </div>
            </div>
            <div class="wx-wrap" @click="wxShow = false">
              <div class="wxboxwrap">
                <img class="wx" src="../assets/pay1.png" alt="" />
                <div class="text">支付宝支付</div>
              </div>
              <div class="lxbox">
                <img class="lx" src="../assets/lx.png" alt="" v-if="!wxShow" />
                <div
                  style="
                    border: 2px solid #999999;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                  "
                  v-else
                ></div>
              </div>
            </div>
          </div>
          <div class="rightbox">
            <img class="payimg" src="../assets/pay.png" alt="" @click="pay()" />
          </div>
        </div>
      </div>
    </div>
    <Bottombar></Bottombar>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Bottombar from "../components/Bottombar";
import axios from "axios";
export default {
  components: {
    Navbar,
    Bottombar
  },
  data() {
    return {
      hotList: "",
      info: "",
      id: "",
      name: "",
      wxShow: true,
      imgShow: 1,
        moneyArray:[
            199,299,599,29
        ],
        userInfo:{}
    };
  },
    mounted() {
        const info = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(info)
    },
    methods: {
    pay() {
        let data = {
            money:this.moneyArray[this.imgShow-1],
            type:this.wxShow ? "wechat" : "alipay",
        }
        console.log(data)
        axios
            .post("https://shop.renguokeji.com/app/v1/order/create"+'?token='+this.userInfo?.token||'',data)
            .then(function (response) {

                if(response.data.code == 1){
                    window.open(response.data.data.pay_url,"_blank");
                }else{
                    alert(response.data.msg)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    },
    receiveFromChild(value){
      this.$router.push({ name: 'about', params: { id: value.id, name: value.name}})
    },
  },
};
</script>

<style scoped>
.content {
  /* width: 100%;
  min-height: 100vh; */
  background-image: url("../assets/banner.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
}
.boxtent{
  padding: 0 6em;
}

.centerbox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(165, 165, 165, 0.5);
}

.wx-wrap {
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding-left: 30px;
  cursor: pointer;
}

.wxboxwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  margin-left: 10px;
  font-size: 14px;
  color: #717171;
  white-space: nowrap;
}

.wx {
  width: 40px;
  height: 40px;
}

.payimg {
  width: 200px;
  /* height: 42px; */
  margin-right: 60px;
  cursor: pointer;
}

.lxbox {
  margin-left: 100px;
}

.lx {
  width: 16px;
  height: 16px;
}

.bgimg {
  width: 260px;
  box-shadow: 0px 0px 10px rgba(165, 165, 165, 0.5);
  margin-right: 30px;
  cursor: pointer;
  border-radius: 14px;
  /* margin: auto 60px; */
}
.bgimg:last-child {
  margin-right: 0;
}
.bgimg1 {
  border: 2px solid #ff3e56;
  box-shadow: 0px 0px 10px rgba(165, 165, 165, 0.5);
  border-radius: 14px;
}
.pay_list{
  margin-top: 40px;
}
.paybanner ,.paybanner img{
  width: 100%;
}
</style>