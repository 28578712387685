<template>
  <div class="box">
    <div class="content_box">
      <div style="padding: 40px 0px 0px 213px">
        <div
          style="
            display: flex;
            align-items: center;
            width: 300px;
            justify-content: center;
          "
        >
          <img src="../assets/icon5.png" style="width: 20px; height: 28px" />
          <div
            style="
              margin-left: 6px;
              color: #ff6f55;
              font-size: 15px;
              font-weight: bold;
            "
          >
            手机验证码
          </div>
          <div style="margin-left: 6px; font-size: 15px; font-weight: bold">
            登录
          </div>
        </div>
        <input
          placeholder="请输入你的手机号"
          v-model="phone"
          style="
            border: 1px solid #e8e8e8;
            border-radius: 6px;
            width: 300px;
            height: 44px;
            outline: none;
            margin-top: 30px;
            padding-left: 10px;
          "
        />
        <div
          style="
            border: 1px solid #e8e8e8;
            width: 300px;
            display: flex;
            align-items: center;
            margin-top: 30px;
            border-radius: 6px;
            cursor: pointer;
          "
        >
          <input
            placeholder="请输入验证码"
            v-model="code"
            style="
              border: none;
              border-radius: 6px;
              width: 200px;
              height: 44px;
              outline: none;
              padding-left: 10px;
            "
          />
          <div
            style="
              color: #ff6f55;
              font-size: 14px;
              width: 100px;
              text-align: center;
            "
            v-if="!showTime"
            @click="sendCode()"
          >
            发送验证码
          </div>
          <div
            style="
              color: #999999;
              font-size: 14px;
              width: 100px;
              text-align: center;
            "
            v-if="showTime"
          >
            {{ time }}S
          </div>
        </div>
        <div class="login" @click="login()">登录</div>
      </div>
      <div
        style="
          position: absolute;
          bottom: 6px;
          left: 216px;
          display: flex;
          align-items: center;
        "
      >
        <div
          style="border: 1px solid #999999; width: 10px; height: 10px"
          v-if="!gmShow"
          @click="gmShow = true"
        ></div>
        <img
          src="../assets/icon6.png"
          style="width: 12px; height: 12px"
          v-else
          @click="gmShow = false"
        />
        <div style="color: #999999; font-size: 10px; margin-left: 4px;cursor: pointer;">
          我已同意<span @click="userAgreement()">《用户协议》</span
          ><span @click="privacyPolicy()">《隐私协议》</span>
        </div>
      </div>
      <img
        src="../assets/close.png"
        class="logn_close"
        @click="changeLogin()"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import axios from "axios";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      hotList: "",
      dataList: "",
      id: "",
      name: "",
      gmShow: false,
      phone: "",
      code: "",
      time: 60,
      showTime: false,
    };
  },
  methods: {
    isValidPhoneNumber(phoneNumber) {
      var regex = /^1[3-9]\d{9}$/;
      return regex.test(phoneNumber);
    },
    sendCode() {
      let that = this;
      const phone = this.isValidPhoneNumber(this.phone);
      if (phone) {
        if (!this.gmShow) {
          alert("请先同意协议");
        } else {
          axios
            .post("https://shop.renguokeji.com/app/v1/sms/send", {
              mobile: this.phone,
              event: "loginregister",
            })
            .then(function (response) {
              that.showTime = true;
              var interval = setInterval(function () {
                if (that.time <= 1) {
                  that.showTime = false;
                  clearInterval(interval);
                  return;
                }
                that.time--;
              }, 1000);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        alert("请输入正确手机号");
      }
    },
    login() {
      let that = this;
      if (!this.code) {
        alert("请输入正确验证码");
      } else {
        axios
          .post("https://shop.renguokeji.com/app/v1/user/mobilelogin", {
            mobile: this.phone,
            captcha: this.code,
          })
          .then(function (response) {
            if (response.data.code != 1) {
              alert(response.data.msg);
            } else {
              console.log(111, response.data.data.userinfo);
              localStorage.setItem(
                "userInfo",
                JSON.stringify(response.data.data.userinfo)
              );
              that.changeLogin();
              window.location.reload();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    userAgreement() {
      const fullPath = this.$router.resolve({ name: 'announce' }).href;
      window.open(fullPath, '_blank');
    },
    privacyPolicy() {
      const fullPath = this.$router.resolve({ name: 'privacy' }).href;
      window.open(fullPath, '_blank');
    },
    changeLogin() {
      this.$emit("changeLogin");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.content_box {
  width: 550px;
  height: 364px;
  background-image: url("../assets/logBg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
}
.login {
  width: 310px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 6px;
  margin-top: 30px;
  background: linear-gradient(90deg, #ff3e56 0%, #ff6f55 100%);
  cursor: pointer;
}
.logn_close {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: -32px;
  cursor: pointer;
}
</style>