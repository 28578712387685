<template>
	<div>
		<Navbar @send="receiveFromChild"></Navbar>
		<div class="box" :style="{ backgroundImage: 'url(' + categoryInfo.bg + ')' }">
			<div class="info_box">
				<img :src="categoryInfo.image" class="title_img" />
				<!-- {{ categoryInfo }} -->
				<div class="img_box">
					<div class="hoem_img" 
					:class="{'hoem_img1':categoryInfo.name=='横屏','hoem_img5':categoryInfo.name=='名片','hoem_img4':(index+1)%3==0&&categoryInfo.name=='横屏','hoem_img6':(index+1)%4==0&&categoryInfo.name=='名片',
					'hoem_img3':(index+1)%6==0&&categoryInfo.name!='横屏'&&categoryInfo.name!='名片'}" v-for="(img,index) in dataList" :key="index" @click="goInfo(img)">
						<img style="width: 100%;height: 100%;object-fit: cover;" :style="{'object-fit: fill;':categoryInfo.name=='横屏'||categoryInfo.name=='名片'}" :src="img.thumbimageurl" />
					</div>
				</div>
			</div>
		</div>
    <Bottombar></Bottombar>
	</div>
</template>

<script>
	import Navbar from "../components/Navbar.vue";
  import Bottombar from "../components/Bottombar";
	import axios from "axios";
	export default {
	  components:{
	    Navbar,
      Bottombar
	  },
	  data() {
	    return{
	      hotList: '',
		  dataList: '',
		  id: '',
		  categoryInfo: '',
		  userInfo: ''
	    }
	  },
	  mounted() {
		let that = this
		// that.id = that.$route.params.id
    that.id = that.$route.params.id || localStorage.getItem('cachedId');
		const info = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(info)
	  	axios.post('https://shop.renguokeji.com/app/v1/poster/list'+'?token='+this.userInfo?.token||'',{
			id: that.id,
			page: 1,
			size: 36,
			keyword: ''
		})
	  	.then(function (response) {
	  		that.dataList=response.data.data.list
	  		that.categoryInfo=response.data.data.categoryInfo
	  	})
	  	.catch(function (error) {
	  	  console.log(error);
	  	});
	  },
	  methods:{
		  receiveFromChild(value){
			  let that = this
			//   that.name = value.name
      localStorage.setItem('cachedId', value.id);
			  axios.post('https://shop.renguokeji.com/app/v1/poster/list'+'?token='+this.userInfo?.token||'',{
				id: value.id,
				page: 1,
				size: 18,
				keyword: ''
			  })
			  .then(function (response) {
				that.dataList=response.data.data.list
				that.categoryInfo=response.data.data.categoryInfo
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		  },
		  goInfo(item){
			  this.$router.push({ name: 'ImgInfo', params: item})
		  }
	  }
	}
</script>

<style scoped>
	.box{
		background-image: url("../assets/contentbg.png");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		padding: 50px 90px;
	}
	.info_box{
		width: 100%;
	}
	.title_img{
		width:  260px;
		height: 70px;
		margin-right: 5px;
		margin-left: 20px;
	}
	.img_box{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 20px;
		background: #fff;
		padding: 30px 20px;
		border-radius: 20px;
		box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 0.06);
	}
	.hoem_img{
		width: 15.2%;
		/* height: 440px; */
		margin-bottom: 30px;
		border-radius: 10px;
		margin-right: 1.7%;
		overflow: hidden;
		cursor: pointer;
	}
	.hoem_img1{
		width: 32.2%;
		height: 270px;
	}
	.hoem_img5{
		width: 23.7%;
		height: 420px;
	}
	.hoem_img3{
		margin-right: 0;
	}
	.hoem_img4{
		margin-right: 0;
	}
	.hoem_img6{
		margin-right: 0;
	}
</style>