<template>
	<div class="box">
		<Navbar @send="receiveFromChild"></Navbar>
		<img src="../assets/infoBanner.png" class="top_img"/>
		<div class="info_box">
			<div class="info_box1">
				<div class="info_box_left">
					<div style="display: flex">
						<img src="../assets/icon2.png" class="icon_img2" />
						<img src="../assets/icon3.png" class="icon_img2" />
						<div>{{info.name}}</div>
						<div class="title_info">作品编号:{{ info.id }}</div>
						<div class="title_info">{{ formatDateTime(info.createtime) }}</div>
					</div>
					<img src="../assets/sc.png" style="height: 34px;width: 88px;cursor: pointer;" alt="" @click="collect()" v-if="collectShow==0">
					<img src="../assets/ysc.png" style="height: 34px;width: 88px;cursor: pointer;" alt="" @click="collect()" v-else>
				</div>
				<img src="../assets/icon4.png" class="icon_img1" />
			</div>
			<div class="info_box1 info_box2">
				<div class="info_bottom_box">
					<img :src="info.thumbimageurl" class="info_bottom_img" :class="info.cid==2?'info_bottom_img1':''" />
				</div>
				<div class="info_bottom_box1">
					<div style="border-radius: 6px;border: 1px solid #F5F5F5;padding: 35px 15px 15px;width: 100%;text-align: center;position: relative;">
						<img src="../assets/pay_icon.png" alt="" style="position: absolute;top: 16%;right: 5%;width: 19%;height: 31%;">
						<img src="../assets/pay2.png" class="icon_img3" @click="download()" />
					</div>
					<div style="border-radius: 6px;border: 1px solid #F5F5F5;padding: 15px;width: 100%;text-align: left;margin-top: 20px;font-size: 14px;color: #999999;">
						<div>格式：{{info.format}}</div>
						<div style="margin-top: 6px;">尺寸：{{info.size}}</div>
					</div>
					<div style="border-radius: 6px;border: 1px solid #F5F5F5;padding: 15px;width: 100%;text-align: left;margin-top: 20px;font-size: 14px;color: #999999;">
						<div>©商用授权</div>
						<!-- <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 10px;">
							<div class="button" @click="download()">
								企业VIP授权套餐
								<div style="color: #FD8320;font-size: 8px;position: absolute;top: -7px;left: 70px;border: 1px solid red;border-radius: 24px;z-index: 999;padding: 1px 12px;background: #F5F5F5;">低至1元/张</div>
							</div>
							<div class="button" @click="download()">单张购买</div>
						</div> -->
						<div style="margin-top: 10px;">版权归属：©{{config.siteName}}</div>
						<div style="margin-top: 10px;">授权范围：全球/全用途商用授权</div>
						<div style="margin-top: 10px;">授权对象：公司/组织/个人均可</div>
						<div style="margin-top: 10px;">授权证明：下载授权书</div>
					</div>
				</div>
			</div>
			<div style="margin-top: 60px">
				<div style="font-size: 14px;margin-left: 3px;">关键词</div>
				<div style="font-size: 12px;border-radius: 5px;border: 1px solid #FF6F55;display: inline-block;padding: 2px 6px;color: #FF6F55;margin-top: 8px;">
					<img src="../assets/icon8.png" style="width: 10px;height: 10px;margin-right: 2px;" alt="" />
					{{ info.keywords }}
				</div>
			</div>
		</div>
		<div style="padding: 80px 3.5% 20px">
			<div style="font-size: 15px;color: #66666B;margin-left: 5px;">相似设计</div>
			<div class="img_box">
                <div class="hoem_img" :class="{'hoem_img1':(index+1)%6==0&&info.cid!=2&&info.cid!=3,'hoem_img2':info.cid==3,
				'hoem_img3':(index+1)%4==0&&info.cid==3,'hoem_img4':info.cid==2,'hoem_img5':(index+1)%3==0&&info.cid==2}"
				v-for="(img,index) in dataList" :key="index" @click="goInfo(img)">
                    <img style="width: 100%;height: 100%;object-fit: cover;" :style="{'object-fit: fill;':info.cid==2||info.cid==3}" :src="img.thumbimageurl" />
                </div>
            </div>
		</div>
    <Bottombar></Bottombar>
	</div>
</template>

<script>
	import Navbar from "../components/Navbar.vue";
  import Bottombar from "../components/Bottombar";
	import axios from "axios";
	export default {
	  components:{
	    Navbar,
      Bottombar,
	  },
	  data() {
	    return{
	      hotList: '',
		  info: '',
		  id: '',
		  name: '',
		  config: '',
		  userInfo: '',
		  collectShow: false,
		  dataList: ''
	    }
	  },
	  mounted() {
		let that = this
		axios.get('https://shop.renguokeji.com/app/v1/config')
		.then(function (response) {
			that.config=response.data.data
		})
		.catch(function (error) {
			console.log(error);
		});
	  	this.info = this.$route.params
		console.log(this.info)
		this.info.is_collection!=0?this.collectShow=true:''
		console.log('aa1a',this.info)
        const info = localStorage.getItem('userInfo');
        this.userInfo = JSON.parse(info)
		this.getUserInfo()
		window.scrollTo({
			top: 0,
			behavior: 'instant'
		});
	  },
	  methods: {
		getUserInfo(){
			let that = this
			axios.post('https://shop.renguokeji.com/app/v1/poster/list'+'?token='+this.userInfo?.token||'',{
				id: that.info.cid,
				page: 1,
				size: 36,
			})
			.then(function (response) {
				that.dataList=response.data.data.list
			})
			.catch(function (error) {
				console.log(error);
			});
		},
		formatDateTime(inputTime) {
			let om=inputTime*1000
			var date = new Date(om);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m;
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			var h = date.getHours();
			h = h < 10 ? ('0' + h) : h;
			var minute = date.getMinutes();
			var second = date.getSeconds();
			minute = minute < 10 ? ('0' + minute) : minute;
			second = second < 10 ? ('0' + second) : second;
			return y+'-'+m+'-'+d;
		},
		collect(){
			let that = this
			if(this.userInfo){
				axios.post('https://shop.renguokeji.com/app/v1/poster/collection'+'?token='+this.userInfo.token||'' ,{
				poster_id: this.info.id
				})
				.then(function (response) {
					if(response.data.code==1){
						response.data.data.status==0?that.collectShow = false:that.collectShow = true
						this.getUserInfo()
					}
					// that.config=response.data.data
				})
				.catch(function (error) {
					console.log(error);
				});
			}else{
				alert('请先登录')
			}
		},
		receiveFromChild(value){
			this.$router.push({ name: 'about', params: { id: value.id, name: value.name}})
		},
		download(){
			const value = localStorage.getItem('userInfo');
			if(value){
				this.$router.push({ name: 'Recharge'})
			}else{
				alert('请先登录')
			}
		},
		goInfo(item){
			// item.id = this.info.id
			console.log(item)
			this.info = item
			this.info.is_collection!=0?this.collectShow=true:this.collectShow=false
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	  }
	}
</script>

<style scoped>
	.box{
		/* text-align: center; */
	}
	.top_img{
		width: 100%;
		height: 94px;
	}
	.info_box{
		width: 74%;
		margin: 20px auto;
	}
	.info_box1{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.info_box_left{
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: left;
		width: 74%;
	}
	.icon_img1{
		width: 24%;
		height: 45px;
	}
	.icon_img2{
		width: 70px;
		height: 20px;
		margin-right: 10px;
	}
	.info_box2{
		margin-top: 6px;
	}
	.icon_img3{
		width: 260px;
		height: 50px;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
	.info_bottom_box{
		width: 74%;
		height: 600px;
		background: #FAFAFA;
		border: 1px solid #E8E8E8;
		display: flex;
		justify-content: center;
		border-radius: 7px;
	}
	.info_bottom_img{
		width: 100%;
		height: 100%;
		border-radius: 30px;
		object-fit: scale-down;
	}
	.info_bottom_img1{
		margin-top: 80px;
		width: 760px;
		height: 420px;
	}
	.info_bottom_box1{
		width: 24%;
		height: 600px;
	}
	.button{
		font-size: 15px;
		font-weight: bold;
		border: 1px solid #00B277;
		color: #00B277;
		border-radius: 24px;
		padding: 8px 16px;
		position: relative;
	}
	.title_info{
		font-size: 14px;
		color: #999999;
		margin-left: 10px;
	}
	.img_box{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		background: linear-gradient( 180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
		padding-top: 20px;
	}
	.hoem_img{
		width: 15.5%;
		/* height: 244px; */
		margin-bottom: 30px;
		border-radius: 10px;
		margin-right: 1.4%;
		overflow: hidden;
		cursor: pointer;
	}
	.hoem_img2{
		width: 23.9%;
		height: 420px;
	}
	.hoem_img4{
		width: 32.4%;
    	height: 286px;
	}
	.hoem_img1{
		margin-right: 0;
	}
	.hoem_img3{
		margin-right: 0;
	}
	.hoem_img5{
		margin-right: 0;
	}
</style>